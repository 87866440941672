import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testingsample',
  templateUrl: './testingsample.component.html',
  styleUrls: ['./testingsample.component.css']
})
export class TestingsampleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
