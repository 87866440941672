import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule} from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { FleetComponent } from './fleet/fleet.component';
import { ContactComponent } from './contact/contact.component';
import { LocationStrategy, Location,PathLocationStrategy, HashLocationStrategy } from '@angular/common';
import { ResultComponent } from './result/result.component';
import { DetailsPaymentComponent } from './details-payment/details-payment.component';
import {ValourHttpService} from './shared/http-service'
import { CommonService } from './shared/common.service';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { apiConstants } from './constants/valour.constants';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BookingComponent } from './booking/booking.component';
import { ToastrModule } from 'ngx-toastr';
import { CountdownModule, CountdownGlobalConfig } from 'ngx-countdown';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ServicesComponent } from './services/services.component';
import { SelfDriveComponent } from './SelfDrive_tariff/self-drive.component';
import { CarRentalPackagesWithDriverAndFuelComponent } from './car-rental-packages-with-driver-and-fuel/car-rental-packages-with-driver-and-fuel.component'; 
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { TaxiDriverRegistrationComponent } from './taxi-driver-registration/taxi-driver-registration.component';
import { HireDriverComponent } from './hire-driver/hire-driver.component';
import { Covid19Component } from './covid19/covid19.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { LuggageCarrierRentComponent } from './luggage-carrier-rent/luggage-carrier-rent.component';
import { PaymentComponent } from './payment/payment.component';
import { environment } from 'src/environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { SelfDriveTermsAndConditionsComponent } from './self-drive-terms-and-conditions/self-drive-terms-and-conditions.component';
import { BlogComponent } from './blog/blog.component';
import { SelfDriveBookingComponent } from './self-drive-booking/self-drive-booking.component';




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    FleetComponent,
    ContactComponent,
    ResultComponent,
    DetailsPaymentComponent,
    BookingComponent,
    ConfirmationComponent,
    PrivacyPolicyComponent,
    ServicesComponent,
    SelfDriveComponent,
    CarRentalPackagesWithDriverAndFuelComponent,
    TermsConditionsComponent,
    TaxiDriverRegistrationComponent,
    HireDriverComponent,
    Covid19Component,
    RegisterComponent,
    LoginComponent,
    ForgotPasswordComponent,
    LuggageCarrierRentComponent,
    PaymentComponent,
    SelfDriveTermsAndConditionsComponent,
    BlogComponent,
    SelfDriveBookingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CountdownModule,
    CommonModule,
    FormsModule,
    Ng2TelInputModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    OwlDateTimeModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    OwlNativeDateTimeModule,
    AgmCoreModule.forRoot({
      apiKey: apiConstants.MAPS_API_KEY,
      libraries: ['geometry','places']
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ],
  providers: [Location,{provide: LocationStrategy, useClass: PathLocationStrategy},ValourHttpService,CommonService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() { } 

 }