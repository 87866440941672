import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {

  public splitPickupPlace:any;
  public splitDropPlace:any;
  public vehicleType:any;
  public noOfPersons:any;
  public date:any;


  constructor(private router: Router) { }

  ngOnInit(): void {

    this.splitPickupPlace=localStorage.getItem('splitPickupPlace');
    this.splitDropPlace=localStorage.getItem('splitDropPlace');
    this.vehicleType=localStorage.getItem('vehicleType');
    this.noOfPersons=localStorage.getItem('noOfPersons');
    this.date=localStorage.getItem('date');

  }

  continue(){
    this.router.navigate(['/booking']);
  }



}
