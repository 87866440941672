import {ValourHttpService} from './http-service'
import { apiConstants } from '../constants/valour.constants';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    constructor(
        private http: HttpClient, private valourService: ValourHttpService) { }

        getAllVehicleTypes() {
            return this.valourService.get(apiConstants.GET_VEHICLE_TYPES);
        }

        sendVerificationCodeToMobile(mobileNumber) {

            let params = new HttpParams();
            params = params.append('mobileNo', mobileNumber);
            return this.valourService.get(`${apiConstants.SEND_VERIFICATION_CODE}?${params}`);
        }


        verifyVerificationCodeToMobile(mobileNumber,code) {

            let params = new HttpParams();
            params = params.append('mobileNo', mobileNumber);
            params = params.append('otpCode', code);
            return this.valourService.get(`${apiConstants.VERIFY_VERIFICATION_CODE}?${params}`);
        }


        sendBookingMail(mobileNumber,pickUpPlace,dropPlace,vehicleType,date,name,noOfPersons) {

            let params = new HttpParams();
            params = params.append('mobileNo', mobileNumber);
            params = params.append('pickUpPlace', pickUpPlace);
            params = params.append('dropPlace', dropPlace);
            params = params.append('vehicleType', vehicleType);
            params = params.append('date', date);
            params = params.append('name', name);
            params = params.append('noOfPassengers',noOfPersons);
            const headers = new Headers();
            headers.append('Access-Control-Allow-Headers', 'Content-Type');
            headers.append('Access-Control-Allow-Methods', 'GET');
            headers.append('Access-Control-Allow-Origin', '*');


            return this.valourService.get(`${apiConstants.SEND_BOOKING_MAIL}?${params}`);
        }

        SendSelfDriveBookingDetails(mobileNumber,pickUpPlace,pickdate,enddate,vehicleType,name,email) {

            let params = new HttpParams();
            params = params.append('mobileNo', mobileNumber);
            params = params.append('customerLocation', pickUpPlace);
            params = params.append('vehicleType', vehicleType);
            params = params.append('startDateAndTime', pickdate);
            params = params.append('endDateAndTime', enddate);
            params = params.append('name', name);
            params = params.append('email', email);
            const headers = new Headers();
            headers.append('Access-Control-Allow-Headers', 'Content-Type');
            headers.append('Access-Control-Allow-Methods', 'GET');
            headers.append('Access-Control-Allow-Origin', '*');


            return this.valourService.get(`${apiConstants.SEND_SELF_DRIVE_BOOKING_MAIL}?${params}`);
        }

        sendTaxiRegistrationMail(mobileNumber,name) {

            let params = new HttpParams();
            params = params.append('mobileNo', mobileNumber);
            params = params.append('name', name);
            const headers = new Headers();
            headers.append('Access-Control-Allow-Headers', 'Content-Type');
            headers.append('Access-Control-Allow-Methods', 'GET');
            headers.append('Access-Control-Allow-Origin', '*');

            return this.valourService.get(`${apiConstants.SEND_TAXI_DRIVER_REGISTRATION}?${params}`);
        }

        sendHireTaxiMail(mobileNumber,name,address,purpose,date,noOfDays) {

            let params = new HttpParams();
            params = params.append('mobileNo', mobileNumber);
            params = params.append('name', name);
            params = params.append('address', address);
            params = params.append('purpose', purpose);
            params = params.append('date', date);
            params = params.append('noOfDays', noOfDays);
            const headers = new Headers();
            headers.append('Access-Control-Allow-Headers', 'Content-Type');
            headers.append('Access-Control-Allow-Methods', 'GET');
            headers.append('Access-Control-Allow-Origin', '*');

            return this.valourService.get(`${apiConstants.SEND_HIRE_DRIVER_MAIL}?${params}`);
        }


        sendContactMail(mobileNumber,name,email,subject,message) {

            let params = new HttpParams();
            params = params.append('mobileNo', mobileNumber);
            params = params.append('name', name);
            params = params.append('email', email);
            params = params.append('subject', subject);
            params = params.append('message', message);
            const headers = new Headers();
            headers.append('Access-Control-Allow-Headers', 'Content-Type');
            headers.append('Access-Control-Allow-Methods', 'GET');
            headers.append('Access-Control-Allow-Origin', '*');

            return this.valourService.get(`${apiConstants.SEND_CONTACT_MAIL}?${params}`);
        }


        addTaxiDriverRegistration(data) {
            const httpOptions = {
                headers: new HttpHeaders({ 'Content-Type': 'application/json' })
            };
            return this.valourService.post(`${apiConstants.ADD_DRIVER_TAXI_REGISTRATION}`, data);
        }

        addHireTaxiDriver(data) {
            const httpOptions = {
                headers: new HttpHeaders({ 'Content-Type': 'application/json' })
            };
            return this.valourService.post(`${apiConstants.ADD_HIRE_DRIVER_TAXI}`, data);
        }


        // getAllVehicleTypes() {
        //     const httpOptions = {
        //         headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        //     };
        //     const apiUrl = 'http://localhost:8181/vwa/api/getVehicleTypes';
        //     return this.http.get(apiUrl);
        // }



}