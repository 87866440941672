<section id="selfdrive-tariff">
    <div class="main-head">
      <p>Self Drive Terms and Conditions</p>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 self-drive-list">
          <h3 class="document">Required Documents (This is only for security reasons)</h3>
          <ol>
            <li>5000 refundable deposit amount</li>
            <li>Aadhar card</li>
            <li>Driving license</li>
            <li>Bike above 2018 model</li>
            <li>Bike original RC</li>
            <li>Local address proof like electricity bill or gas bill or any id</li>
          </ol>
          <p class="note">If there is no bike</p>
          <ol>
            <li>20000 refundable deposit amount</li>
            <li>One blank signed cheque required</li>
          </ol>
          <p class="note">For Daily Booking</p>
          <ol>
            <li>Refundable deposit and number of days booking amount should be paid at the time of taking the vehicle.</li>
          </ol>
          <p class="note">For Monthly Booking</p>
          <ol>
            <li>Refundable deposit, 2 months advance and running month rental amount should be paid at the time of taking the vehicle.</li>
          </ol>
        </div>
        <div class="col-lg-12 self-drive-list">
          <br>
          <h3 class="terms">Terms & Conditions</h3>
          <p class="pad-17">I (“customer”, “client”) accept the following terms and conditions and take the vehicle for rent with self
            drive and I am liable for any damages, theft, police charges, criminal charges,etc. Bhukya Tours and Travels
            aka Yodrives has every right to proceed legally.</p>
          <ol>
            <!-- <li>Insurance For accident shall be made only Rs 50,000/- above</li>
                      <li>The Rental for Repairing Days shall be applicable Rentals Charges</li>
                      <li>If any accident are Damage s accurs you should inform us. With out any information if u done any repairs we are not accept.</li>
                      <li>Before you take a vehcile you have to take the photos of the vehicle and you have to return the vehicle same condition</li>
                      <li>Confirmation of your booking you have to deposit 50% on Total Amount</li>
                      <li>We are not provide unlimited KM. Average KM 300 per day.</li>
                      <li>Advance Amount not refunded</li> -->
            <li>I have taken the video and pictures of the vehicle before hiring.I will handover the vehicle in the same
              condition.</li>
            <li>I agree kilometers and Hours will be calculated from garage to garage.</li>
            <li>I agree car fuel level/range should be checked and handed over at the same fuel level.</li>
            <li>I agree to pay minimum Rs 500 to Rs 1000 for pickup or drop car at my location.</li>
            <li>I agree speed is limited as per the limit specified by the RTA local jurisdiction. However, the maximum
              speed limit is 80km/hour is allowed and exceeding shall void the insurance claim benefits and will attract a
              penalty of Rs 500 per violation.</li>
            <li>I agree that toll, parking ,interstate permit etc. not included in the above cost has to pay extra as
              applicable.</li>
            <li>I agree any cancellation should be done before 24 hours, otherwise one day booking amount is charged for
              cancellation.</li>
            <li>I agree that I will pay all Govt traffic violation challans during my trip.</li>
            <li>I agree drunken driving, smoking and gutka is strictly prohibited while driving the car and will be
              penalized for Rs 5000 if found.</li>
            <li>I agree that the company reserves the right to charge a fee for specific predefined accessories.</li>
            <li>I agree I am liable and responsible for any unauthorized activities like mortgages and illegal activities
              like drug supply,crimes,etc during the hire and responsible for the police case completely and handover the
              car safely to the company.</li>
            <li>I agree If the vehicle is damaged, firstly the information should be passed to the company and can get it
              fixed or repaired at the authorized showroom only and can return the car in original condition.</li>
            <li>I agree If there is any damage to the car/vehicle, repair or fixing to original condition is to be done by
              me up to Rs 25000/- to 50000/ depending upon the car. Beyond the cost of Rs.50000 can avail the insurance
              claim benefit for which the process might take 7 to 30 working days or more days. I agree to pay a security
              deposit of estimated damage cost which will be refunded after the vehicle is repaired and insurance is
              claimed. I should pay a daily rental amount during the insurance claim and repair period.</li>
            <li>I agree that I will be liable for the full cost of damage to the vehicle + miscellaneous expenses arising
              out of the damages caused by wrong fuelling.</li>
            <li>I agree to take care of belongings before handing over the car and company is not responsible for my
              belongings in car or office.</li>
            <li>I agree I will Inform before 24 hrs for extension and pay extension fee Rs 500 + original rent amount.
            </li>
            <li>I agree to do a video call and share live location any time if required from the company.</li>
            <li>I agree all rentals should be paid in advance and delay in payment shall attract a penalty of 10% per day
              for the pending payable amount.</li>
            <li>I agree that I should inform the company about the route or place I will be travelling. If driving to a
              different route or area will lead to termination of deposit and shall lead to legal escalations.</li>
            <li>I agree any extension or delay should be informed in advance and can only extend with the permission of
              the company and any extension without permission will lead to termination of deposit as it may cause
              inconvenience to the next customer.</li>
            <li>I agree If the car is returned in a filth condition or with any odors that might cause inconvenience to
              the next customer will lead to a charge of Rs 500 for car wash or interior cleaning.</li>
            <li>I agree that the refundable deposit amount will be refunded after 3 days of car return.</li>
            <li>I agree to pay a total amount for bad driving repair or damage of the car from pickup time to return time
              ( For Clutch Plates bad driving, Shock Absorbers damage ,Tyres Damage ,Engine Seized due to over heat or
              engine damage).</li>
            <li>I agree that the company can take any action upon my deposits for any damage done by me.</li>
            <li>I agree that I need to call the company one hour before returning the vehicle.</li>
            <li>I agree I am keeping my bike as a security deposit with No —-------- OR Cheque No —---------- .</li>
            <li>I agree that the company has the right to terminate the car at any moment.</li>
            <li>I agree for more detailed information of terms and conditions, I will go through updated information on
              the website www.yodrives.com and obey it.</li>
            <li>I agree I am submitting the required documents: Aadhar card , Driving licence, Two wheeler, RC original,
              blank cheques with signature, current bill or gas bill.</li>
          </ol>
        </div>
        
      </div>
    </div>
  </section>