import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {AppConfig} from '../../environments/environment'
 
/**
 * VSA Http service to wrap around REST Calls.
 */
@Injectable()
export class ValourHttpService {
    private endPoint = encodeURI(AppConfig.baseURI + AppConfig.authURI);

    constructor(private httpClient: HttpClient) {
    }

    /**
     * GET method requests a resource from the server along with query parameters if required
     */
    get(path: string,
        options?: {
            headers?: HttpHeaders | {
                [header: string]: string | string[];
            };
            observe?: 'body';
            params?: HttpParams | {
                [param: string]: string | string[];
            };
            reportProgress?: boolean;
            responseType?: 'json';
            withCredentials?: boolean;
        }): Observable<any> {
        return this.httpClient.get(`${this.endPoint}${path}`, options);
    }

    /**
     * Utility method for Update.
     */
    put(path: string, body: any = {}, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<any> {
        return this.httpClient.put(
            `${this.endPoint}${path}`,
            body, options);
    }

    /**
     * Utility method for Create.
     */
    post(path: string, body: any = {}, options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };
        observe?: 'body';
        params?: HttpParams | {
            [param: string]: string | string[];
        };
        reportProgress?: boolean;
        responseType?: 'json';
        withCredentials?: boolean;
    }): Observable<any> {
        return this.httpClient.post(
            `${this.endPoint}${path}`, body, options);
    }

    /**
     * Utility method for Delete.
     */
    delete(path: string): Observable<any> {
        return this.httpClient.delete(
            `${this.endPoint}${path}`);
    }

    deleteWithObject(path: string, body: any = {}): Observable<any> {
        return this.httpClient.delete(
            `${this.endPoint}${path}`, body);
    }

    patch(path: string, body: any = {}): Observable<any> {
        return this.httpClient.patch(
            `${this.endPoint}${path}`,
            body);
    }
}

