import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  title = 'Sanitized car rental in Hyderabad tariff  | Car rental Hyderabad | book cabs online | yodrives.com';

  constructor(
    private titleService: Title,
    private metaTagService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'keywords', content: 'car booking tariff in hyderabad,Car rental in Hyderabad, Sanitized car rental in Hyderabad, hourly cab rentals Hyderabad,  rent a car with driver Hyderabad, Disinfected car rental in Hyderabad,Hyderabad Cabs , Car Hire , Taxi India , book cabs , Cab online , Taxi Cabs , Call Cab , Taxi on Call , Airport Transfer' },
      { name: 'author', content: 'yodrives - Bhukya Tours and Travels' },
      { name: 'keyphrase', content: 'car booking tariff in hyderabad,Car rental in Hyderabad, Sanitized car rental in Hyderabad, hourly cab rentals Hyderabad,  rent a car with driver Hyderabad, Disinfected car rental in Hyderabad,Hyderabad Cabs , Car Hire , Taxi India , book cabs , Cab online , Taxi Cabs , Call Cab , Taxi on Call , Airport Transfer' },
      { name: 'description', content: 'Sanitized and disinfected car rental in Hyderabad. Hire a local hourly cab or book outstation taxi service in Hyderabad at an affordable price with YoDrives' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);
  }

}
