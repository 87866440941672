<section id="selfdrive-tariff" class="search-sec">
  <div class="main-head">
    <p>Self Drive without Driver and Fuel</p>
  </div>
  
  <div class="container">
    <div class="selfdrive-form">
      <form [formGroup]="selfdriveForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-12 p-0 input-wrapper location">
                        <input type="text" formControlName="firstName" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
                    class="form-control search-slt" (keydown.enter)="$event.preventDefault()" placeholder="My Location"
                    autocorrect="on" autocapitalize="on" spellcheck="on" #searchPlaces/>
                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                      <div *ngIf="f.firstName.errors.required">Pick-up place is required</div>
                    </div>
  
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12 p-0 input-wrapper pickkdate">
                        <input type="text" formControlName="pickdate" [ngClass]="{ 'is-invalid': submitted && f.pickdate.errors }"
                    placeholder="Start Date and Time" class="form-control search-slt calender" [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" [min]="min"/>
                    <owl-date-time #dt1></owl-date-time>
                    <div *ngIf="submitted && f.pickdate.errors" class="invalid-feedback">
                      <div *ngIf="f.pickdate.errors.required">Start Date is required</div>
                    </div>
                        
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12 p-0 input-wrapper endddate">
                      <input type="text" formControlName="enddate" [ngClass]="{ 'is-invalid': submitted && f.enddate.errors }"
                    placeholder="End Date and Time" class="form-control search-slt calender" [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2" [min]="min"/>
                    <owl-date-time #dt2></owl-date-time>
                    <div *ngIf="submitted && f.enddate.errors" class="invalid-feedback">
                      <div *ngIf="f.enddate.errors.required">End Date is required</div>
                    </div>
                      
                  </div>
                    <div class="col-lg-2 col-md-2 col-sm-12 p-0 input-wrapper vehicle">
                      <select id="inputState" formControlName="vehicleType" [(ngModel)]="selectedProp"
                      [ngClass]="{ 'is-invalid': submitted && f.vehicleType.errors }" class="form-control search-slt">
                      <option value="">Vehicle Type</option>
                      <option value="5 Seater">5 - Seater</option>
                      <option value="6 Seater">6 - Seater</option>
                      <option value="7 Seater">7 - Seater</option>
                      <option value="8 Seater">8 - Seater</option>
                      <!-- <option *ngFor="let vehicleType of vehicleTypesData" [ngValue]="vehicleType.vcVehicleType">
                        {{vehicleType.vcVehicleType}}</option> -->
                    </select>
                    <div *ngIf="submitted && f.vehicleType.errors" class="invalid-feedback">
                      <div *ngIf="f.vehicleType.errors.required">Please select vehicle type</div>
                    </div>
                    </div>
                    <div class="col-lg-1 col-md-1 col-sm-12 p-0">
                        <button type="submit" class="btn btn-danger wrn-btn form-control">Search</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    </div>
    <div class="row">
   

      <div class="col-lg-12 self-drive-list">
        <!-- <h3>Self Drive without Driver and Fuel</h3> -->
        <br>
        <table class="table table-bordered table-responsive w-100 d-block d-md-table" style="margin-bottom: 0px;">
          <thead class="thead-blue">
            <tr>
              <!-- <th scope="col">S No</th> -->
              <th scope="col">Car Name</th>
              <th scope="col">1 Day</th>
              <th scope="col">7 Days +</th>
              <th scope="col">15 Days +</th>
              <th scope="col">30 Days</th>
              <th scope="col">Automatic</th>
              <th scope="col">Extra Km</th>
              <th scope="col">Extra Hour</th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-grey">
              <!-- <th scope="row">1</th> -->
              <td><b>Alto/Kwid</b></td>
              <td>₹1800</td>
              <td>₹1500</td>
              <td>₹1200</td>
              <td>₹900</td>
              <td>+₹100</td>
              <td>₹5</td>
              <td>₹200</td>
            </tr>
            <tr class="bg-grey">
              <!-- <th scope="row">2</th>
                        <td>BALENO/i20/S CROSS/BREEZA/VENTO/CIAZ/VERNA</td>
                        <td>2800</td>
                        <td>2700*3 Days</td>
                        <td>2500*6 Days</td>
                        <td>45000</td> -->
              <td><b>Swift/i10</b></td>
              <td>₹2000</td>
              <td>₹1800</td>
              <td>₹1500</td>
              <td>₹1100</td>
              <td>+₹150</td>
              <td>₹6</td>
              <td>₹200</td>
            </tr>
            <tr class="bg-grey">
              <!-- <th scope="row">3</th>
                        <td>ERTIGA/OLD INNOVA/ECO SPORTS/CRETA/NEW SCORPIO</td>
                        <td>3200</td>
                        <td>3100*3 Days</td>
                        <td>3000*6 Days</td>
                        <td>48000</td> -->
              <td><b>Baleno/i20/Glanza</b></td>
              <td>₹2200</td>
              <td>₹2000</td>
              <td>₹1800</td>
              <td>₹1500</td>
              <td>+₹150</td>
              <td>₹6</td>
              <td>₹200</td>
            </tr>
            <tr class="bg-grey">
              <!-- <th scope="row">4</th>
                        <td>INNOVA CRYSTA/XUV-500/TATA HEXA/JEEP</td>
                        <td>4000</td>
                        <td>3800*3 Days</td>
                        <td>3600*6 Days</td>
                        <td>60000</td> -->
              <td><b>Dzire/Aspire</b></td>
              <td>₹2300</td>
              <td>₹2000</td>
              <td>₹1800</td>
              <td>₹1500</td>
              <td>+₹150</td>
              <td>₹6</td>
              <td>₹200</td>
            </tr>
            <tr class="thead-hatch">
              <!-- <th scope="row">5</th>
                        <td>OLD FORTUNER</td>
                        <td>5000</td>
                        <td>4800*3 Days</td>
                        <td>4600*6 Days</td>
                        <td>70000</td> -->
              <td><b>Ciaz/Verna</b></td>
              <td>₹2700</td>
              <td>₹2400</td>
              <td>₹2200</td>
              <td>₹1800</td>
              <td>+₹200</td>
              <td>₹7</td>
              <td>₹250</td>
            </tr>
            <tr class="thead-hatch">
              <!-- <th scope="row">6</th>
                        <td>NEW FORTUNER/NEW ENDEAVOUR/NEW PAJERO SPORTS</td>
                        <td>8000</td>
                        <td>7500*3 Days</td>
                        <td>7000*6 Days</td>
                        <td>90000</td> -->
              <td><b>Brezza</b></td>
              <td>₹2500</td>
              <td>₹2200</td>
              <td>₹2000</td>
              <td>₹1700</td>
              <td>+₹200</td>
              <td>₹7</td>
              <td>₹250</td>
            </tr>
            <tr class="thead-hatch">
              <td><b>SCross/Venue/Duster</b></td>
              <td>₹2800</td>
              <td>₹2400</td>
              <td>₹2100</td>
              <td>₹1800</td>
              <td>+₹200</td>
              <td>₹7</td>
              <td>₹250</td>
            </tr>
            <tr class="thead-hatch">
              <td><b>Creta/Seltos</b></td>
              <td>₹3000</td>
              <td>₹2700</td>
              <td>₹2500</td>
              <td>₹2200</td>
              <td>+₹250</td>
              <td>₹8</td>
              <td>₹300</td>
            </tr>
            <tr class="bg-grey">
              <td><b>Ertiga</b></td>
              <td>₹3200</td>
              <td>₹3000</td>
              <td>₹2700</td>
              <td>₹2500</td>
              <td>+₹250</td>
              <td>₹8</td>
              <td>₹300</td>
            </tr>
            <tr class="bg-grey">
              <td><b>Innova/Marazzo/XL6</b></td>
              <td>₹3500</td>
              <td>₹3000</td>
              <td>₹2800</td>
              <td>₹2600</td>
              <td>+₹300</td>
              <td>₹9</td>
              <td>₹300</td>
            </tr>
            <tr class="bg-grey">
              <td><b>Crysta/XUV500</b></td>
              <td>₹4500</td>
              <td>₹4000</td>
              <td>₹3500</td>
              <td>₹3000</td>
              <td>+₹300</td>
              <td>₹10</td>
              <td>₹300</td>
            </tr>
            <tr class="bg-grey">
              <td><b>Endeavor/Fortuner</b></td>
              <td>₹8000</td>
              <td>₹7500</td>
              <td>₹7000</td>
              <td>₹6500</td>
              <td>+₹400</td>
              <td>₹12</td>
              <td>₹400</td>
            </tr>
            <tr class="thead-yellow">
              <td><b>KMs Limit per Day</b></td>
              <td>300 KMs</td>
              <td>250 KMs</td>
              <td>200 KMs</td>
              <td>150 KMs</td>
              <td> - </td>
              <td> - </td>
              <td> - </td>
            </tr>
          </tbody>
        </table>
        <i style="font-size:0.7rem">Note: Above prices may vary based on car model, year and KMs run.</i>
        <br><br>
        <!-- <p class="note">Note : Minimum booking time 12Hours</p>
                  <p class="note">Every extra hours 1-2(Rs.150), 3-4(Rs.250), 5-7(Rs.700).All the Rental Charges Excluding Fuel</p> -->
        <h3 class="document">Required Documents (This is only for security reasons)</h3>
        <ol>
          <li>₹3000 and ₹5000 refundable deposit amount for Sedan and SUV respectively</li>
          <li>Aadhar card, Driving license</li>
          <!-- <li>Driving license</li> -->
          <li>Bike (Two Wheeler)  above 2018 model above,Bike original RC</li>
          <!-- <li>Bike original RC</li> -->
          <li>Local address proof like electricity bill or gas bill or rental agreement, etc...</li>
        </ol>
        <p class="note">If there is no bike</p>
        <ol>
          <li>₹20000 refundable deposit amount and one blank signed cheque required</li>
          <!-- <li>One blank signed cheque required</li> -->
        </ol>
        <p class="note">For Daily Booking</p>
        <ol>
          <!-- <li>Refundable deposit and number of days booking amount should be paid at the time of taking the vehicle.</li> -->
          <li>Confirm booking in advance by paying a token amount of ₹2000 to ₹5000. Refundable deposit and number of days booking amount should be paid at the time of taking the vehicle. Refundable deposit is paid after 48 hours of returning the vehicle by checking fast tag tolls and traffic challans if any</li>
        </ol>
        <p class="note">For Monthly Booking</p>
        <ol>
          <li>Refundable deposit, 2 months advance and running month rental amount should be paid at the time of taking the vehicle.</li>
        </ol>
        <h3 class="terms">Terms & Conditions</h3>
        <p class="pad-17">I (“customer”, “client”) accept the following terms and conditions and take the vehicle for rent with self
          drive and I am liable for any damages, theft, police charges, criminal charges,etc. Bhukya Tours and Travels
          aka Yodrives has every right to proceed legally.</p>
        <ol>
          <!-- <li>Insurance For accident shall be made only Rs 50,000/- above</li>
                    <li>The Rental for Repairing Days shall be applicable Rentals Charges</li>
                    <li>If any accident are Damage s accurs you should inform us. With out any information if u done any repairs we are not accept.</li>
                    <li>Before you take a vehcile you have to take the photos of the vehicle and you have to return the vehicle same condition</li>
                    <li>Confirmation of your booking you have to deposit 50% on Total Amount</li>
                    <li>We are not provide unlimited KM. Average KM 300 per day.</li>
                    <li>Advance Amount not refunded</li> -->
          <li>I have taken the video and pictures of the vehicle before hiring.I will handover the vehicle in the same
            condition.</li>
          <li>I agree kilometers and Hours will be calculated from garage to garage.</li>
          <li>I agree car fuel level/range should be checked and handed over at the same fuel level.</li>
          <li>I agree to pay minimum Rs 500 to Rs 1000 for pickup or drop car at my location.</li>
          <li>I agree speed is limited as per the limit specified by the RTA local jurisdiction. However, the maximum
            speed limit is 80km/hour is allowed and exceeding shall void the insurance claim benefits and will attract a
            penalty of Rs 500 per violation.</li>
          <li>I agree that toll, parking ,interstate permit etc. not included in the above cost has to pay extra as
            applicable.</li>
          <li>I agree any cancellation should be done before 24 hours, otherwise one day booking amount is charged for
            cancellation.</li>
          <li>I agree that I will pay all Govt traffic violation challans during my trip.</li>
          <li>I agree drunken driving, smoking and gutka is strictly prohibited while driving the car and will be
            penalized for Rs 5000 if found.</li>
          <li>I agree that the company reserves the right to charge a fee for specific predefined accessories.</li>
          <li>I agree I am liable and responsible for any unauthorized activities like mortgages and illegal activities
            like drug supply,crimes,etc during the hire and responsible for the police case completely and handover the
            car safely to the company.</li>
          <li>I agree If the vehicle is damaged, firstly the information should be passed to the company and can get it
            fixed or repaired at the authorized showroom only and can return the car in original condition.</li>
          <li>I agree If there is any damage to the car/vehicle, repair or fixing to original condition is to be done by
            me up to Rs 25000/- to 50000/ depending upon the car. Beyond the cost of Rs.50000 can avail the insurance
            claim benefit for which the process might take 7 to 30 working days or more days. I agree to pay a security
            deposit of estimated damage cost which will be refunded after the vehicle is repaired and insurance is
            claimed. I should pay a daily rental amount during the insurance claim and repair period.</li>
          <li>I agree that I will be liable for the full cost of damage to the vehicle + miscellaneous expenses arising
            out of the damages caused by wrong fuelling.</li>
          <li>I agree to take care of belongings before handing over the car and company is not responsible for my
            belongings in car or office.</li>
          <li>I agree I will Inform before 24 hrs for extension and pay extension fee Rs 500 + original rent amount.
          </li>
          <li>I agree to do a video call and share live location any time if required from the company.</li>
          <li>I agree all rentals should be paid in advance and delay in payment shall attract a penalty of 10% per day
            for the pending payable amount.</li>
          <li>I agree that I should inform the company about the route or place I will be travelling. If driving to a
            different route or area will lead to termination of deposit and shall lead to legal escalations.</li>
          <li>I agree any extension or delay should be informed in advance and can only extend with the permission of
            the company and any extension without permission will lead to termination of deposit as it may cause
            inconvenience to the next customer.</li>
          <li>I agree If the car is returned in a filth condition or with any odors that might cause inconvenience to
            the next customer will lead to a charge of Rs 500 for car wash or interior cleaning.</li>
          <li>I agree that the refundable deposit amount will be refunded after 3 days of car return.</li>
          <li>I agree to pay a total amount for bad driving repair or damage of the car from pickup time to return time
            ( For Clutch Plates bad driving, Shock Absorbers damage ,Tyres Damage ,Engine Seized due to over heat or
            engine damage).</li>
          <li>I agree that the company can take any action upon my deposits for any damage done by me.</li>
          <li>I agree that I need to call the company one hour before returning the vehicle.</li>
          <li>I agree I am keeping my bike as a security deposit with No —-------- OR Cheque No —---------- .</li>
          <li>I agree that the company has the right to terminate the car at any moment.</li>
          <li>I agree for more detailed information of terms and conditions, I will go through updated information on
            the website www.yodrives.com and obey it.</li>
          <li>I agree I am submitting the required documents: Aadhar card , Driving licence, Two wheeler, RC original,
            blank cheques with signature, current bill or gas bill.</li>
        </ol>
      </div>
    </div>
  </div>
</section>