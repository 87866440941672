import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-luggage-carrier-rent',
  templateUrl: './luggage-carrier-rent.component.html',
  styleUrls: ['./luggage-carrier-rent.component.css']
})
export class LuggageCarrierRentComponent implements OnInit {

  title = 'YoDrives car luggage carrier rent for Mahindra Marazzo and Innova Crysta | Car carrier for rent| car luggage carrier for rent | Innova crysta carrier for rent | Mahindra marazzo carrier for rent | Car rooftop luggage carrier for rent | yodrives.com';
  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }
 
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'keywords', content: 'car carrier for rent, car luggage carrier for rent, innova crysta carrier for rent, mahindra marazzo carrier for rent, car rooftop luggage carrier for rent' },
      { name: 'author', content: 'yodrives - Bhukya Tours and Travels' },
      { name: 'keyphrase', content: 'car carrier for rent, car luggage carrier for rent, innova crysta carrier for rent, mahindra marazzo carrier for rent, car rooftop luggage carrier for rent, Self drive cars in Hyderabad, automatic self drive cars, manual and automatic self drive cars, daily and monthly self drive car rental in Hyderabad,Monthly car subscription in Hyderabad,car lease in Hyderabad for self drive, car rentals without driver, corporate car lease in Hyderabad, corporate employee car rentals, SUV self drive rentals, car booking tariff in hyderabad,Car rental in Hyderabad, Sanitized car rental in Hyderabad, hourly cab rentals Hyderabad,  rent a car with driver Hyderabad, Disinfected car rental in Hyderabad,Hyderabad Cabs , Car Hire , Taxi India , book cabs , Cab online , Taxi Cabs , Call Cab , Taxi on Call , Airport Transfer' },
      { name: 'description', content: 'yodrives car luggage carrier rent for Mahindra Marazzo and Innova Crysta, rooftop car carrier for rent' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);
  }

}
