<section id="register_component">
<div class="container">
    <div class="row justify-center">
        <div class="col-lg-4 col-md-6">
            <span class="anchor" id="formRegister"></span>
            <div class="card card-outline-secondary">
                <div class="card-header">
                    <h5 class="mb-0">REGISTER</h5>
                </div>
                <div class="card-body">
                    <form class="form" role="form" autocomplete="off">
                        <div class="form-group">
                            <input type="text" class="form-control" id="inputName" placeholder="Name">
                        </div>
                        <div class="form-group">
                            <input type="email" class="form-control" id="inputEmail3" placeholder="Email" required="">
                        </div>
                        <div class="form-group">
                            <div class="input-group mb-2">
                                <div class="input-group-prepend">
                                  <div class="input-group-text">+ 91</div>
                                </div>
                            <input type="text" class="form-control" id="inputEmail3" placeholder="Mobile Number" required="" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10">
                        </div>
                    </div>
                        <div class="form-group">
                            <input type="password" class="form-control" id="inputPassword3" placeholder="Password" required="">
                        </div>
                        <div class="form-group">
                            <input type="password" class="form-control" id="inputVerify3" placeholder="Re-enter Password" required="">
                        </div>
                        <p>By clicking "<span class="regist">Register</span>" you agree to the <a routerLink="/Terms_Conditions" routerLinkActive="active">Terms & Conditions</a></p>
                        <div class="form-group float-right">
                            <button type="submit" class="btn  cancel">Cancel</button><button type="submit" class="btn register"  style="margin-left: 15px;">Register</button>
                        </div>
                    </form>
                    <br>
                </div>
                <div>
                    <p class="text-center">Already have an account? <a routerLink="/Login" routerLinkActive="active">Login</a></p>
                </div>
            </div>
            <!-- /form card register -->
        </div>
    </div>
</div>
</section>
