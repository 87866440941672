<section id="login_component">
    <div class="container">
        <div class="row justify-center">
            <div class="col-lg-4 col-md-6">
                <span class="anchor" id="formRegister"></span>
                <div class="card card-outline-secondary shadow-2xl">
                    <div class="card-header">
                        <h5 class="mb-0">SIGN IN</h5>
                    </div>
                    <div class="card-body">
                        <form class="form" role="form" autocomplete="off">
                            <div class="form-group">
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">+ 91</div>
                                    </div>
                                <input type="text" class="form-control" id="inputEmail3" placeholder="Mobile Number" required="" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10">
                            </div>
                        </div>
                            <div class="form-group">
                                <input type="password" class="form-control" id="inputPassword3" placeholder="Password" required="">
                                <p class="float-right frgt-pswd"><a [routerLink]="['/Forgot_Password']" routerLinkActive="active">Forgot Your Password?</a></p>
                            </div>
                            <div class="form-group butn-form text-center">
                                <button type="submit" class="btn  signIn">Sign In</button>
                            </div>
                        </form>
                    </div>
                    <div>
                        <p class="text-center acnt-cls">Don't have an account?</p>
                        <p class="text-center crt-cls"><a routerLink="/Register" routerLinkActive="active">Create new account</a></p>
                    </div>
                </div>
                <!-- /form card register -->
            </div>
        </div>
    </div>
    </section>
    