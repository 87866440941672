
<section id="booking-content">
    <div class="text-center">
        <div class="row body-pad-btm ">
            <div class="col-lg-12 confirm-bod">
                <h3 style="text-align: left;">Dear {{displayName}},</h3>
                <h3></h3>
                <img src="../assets/images/thankyou6.png" alt="">
            </div>
        </div>
    </div>
</section>

<section id="booking-twocontent">
    <div class="text-center">
        <div class="row body-pad-btm ">
            <div class="col-lg-12 confirm-body">
                <h3 style="text-align: left;">Dear {{displayName}},</h3>
                <h3>Date madhan</h3>
                <img src="../assets/images/thankyou2.png" alt="">
            </div>
        </div>
    </div>
</section>