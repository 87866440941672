<section id="terms-content">
    <div class="main-head">
        <p>Terms and Conditions</p>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h3 class="main-h3">Introduction</h3>
                <h5>YO Drives | Bhukya Tours and Travels</h5>
                <p>Updated effective from 20th September, 2020</p>
                <p>Welcome to our website.Throughout the site, the terms “we”, “us” and “our” refer to YoDrives.  If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern YODrives's relationship with you in relation to this website. If you disagree with any part of these terms and conditions, please do not use our website or avail any of the services being provided therein.</p>
                <h3>Ownership</h3>
                <p>This Site/ Application/ Services is/are operated /provided by Bhukya Tours and Travels.
                    The term 'you' refers to the user or viewer of our website.Your agreement to these user terms shall operate as a binding agreement between you and YoDrives in respect of the use and services of the site.
                </p>
                <p>Your acceptance of the User Terms shall be deemed to include your acceptance of the privacy policy. By accepting these User Terms, you also allow YoDrives to send you promotional emails and SMS alerts from time to time.</p>
                <h3>Terms of Use:</h3>
                <p>The use of this website is subject to the following terms of use:</p>
                <ol>
                    <li>These terms and conditions (“User Terms”) apply to Your visit to and use, of the Site whether through a computer or a mobile phone, the Service and the Application, as well as to all information, recommendations and or services provided to You on or through the Site, the Service and the Application.</li>
                    <li>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
                    <li>This website uses cookies to monitor browsing preferences. If you do allow cookies to be used, the following personal information may be stored by us for use by third parties: IP Address, Location ,etc</li>
                    <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
                    <li>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.</li>
                    <li>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
                    <li>All trademarks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.</li>
                    <li>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offence.</li>
                    <li>From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</li>
                    <li>Your use of this website and any dispute arising out of such use of the website is subject to the laws of India.</li>
                    <li>Specific offers will have might have additional Terms & Conditions which the user has to comply with in case he chooses to avail that offer.</li>
                </ol>
                <h3>Prohibited Uses</h3>
                <p>In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content:</p>
                <ol>
                    <li>For any unlawful purpose;</li>
                    <li>To solicit others to perform or participate in any unlawful acts;</li>
                    <li>To violate any international, federal, provincial or state regulations, rules, laws, or local ordinances;</li>
                    <li>To infringe upon or violate our intellectual property rights or the intellectual property rights of others;</li>
                    <li>To harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;</li>
                    <li>To submit false or misleading information;</li>
                    <li>To upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet;</li>
                    <li>To collect or track the personal information of others;</li>
                    <li>To spam, phish, spider, crawl, or scrape;</li>
                    <li>For any obscene or immoral purpose; or</li>
                    <li>To interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet.</li>
                </ol>
                <h3>Cancellation and Returns</h3>
                <p>You may cancel the booking 48 hours prior to the time of journey, without any cancellation charges for all services. In case cancellation or shorting of the trip is requested within 48 hours of the pick-up time, then following rules will apply:</p>
                <ol>
                    <li>Multi Day trip: The charge for the first day would be deducted from the total amount and refund will be issued to the user.</li>
                    <li>Single Day trip/ Airport transfer: No Refund will be issued to the user.</li>
                    <li>Airport transfer: No Cancellation Charges if Cancelled at least 4 hours prior of pickup time.</li>
                </ol>
                <h3>Refunds</h3>
                <p>If you are eligible for refunds based on the “Cancellation and Returns” policy above, then the refund will be remitted back to you in 5-7 working days. In case of any issues, write to us at info@yodrives.com or call us at 8885255 144/199</p>
                <h3>Force Majeure</h3>
                <p>YoDrives shall not be liable or responsible for its failure to perform or delay in performance of the service obligations if such failure or delay is attributable to or arises out of any Force Majeure Event.</p>
                <p>"Force Majeure Event" means an event that is not within the reasonable control of the Party whose performance is affected thereby and shall include any of the following events:</p>
                <ol>
                    <li>acts of God or elements (such as, but not limited to, fire, explosions, drought, tidal waves, flood, frost, earthquake, storm, lightning, hurricane, landslide, tornado, epidemic or pandemic),</li>
                    <li>war, hostilities (whether war be declared or not), invasion, act of foreign enemies, mobilisation, requisition, or embargo;</li>
                    <li>rebellion, revolution, insurrection, violent demonstrations, sabotage, or military or usurped power, or civil war;</li>
                    <li>riot, commotion, strikes, go slows, lock outs or disorder, breach of peace, civil unrest, labour unrest,
                    </li>
                    <li>acts or threats of terrorism.</li>
                    <li>action of statutory authorities, unions, local or central governments, civil or military authorities,</li>
                    <li>telecommunication failure, quarantine or due to any other unforeseeable circumstances beyond the control of YoDrives.</li>
                </ol>
                <p>For the duration of a Force Majeure event, the service obligations of YoDrives will be put on hold. Once the event has ended, the service may recommence. YoDrives shall not be responsible for disruption or cancellation of customer plans due to cancellation or delay in services by YoDrives due to reasons attributable to Force Majeure Events. Our service levels may be degraded while such Force Majeure events shall in effect and we shall in earnest, try to make you aware of how it affects our service.</p>
                <p><a href="./Covid19_Travel_Guidelines"><i>Covid19 Travel Guidelines..</i></a></p>

            </div>
        </div>
    </div>

</section>
