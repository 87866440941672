<section id="blog">
    <div class="main-head">
      <p>Yo Drives Blog</p>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 Tourist-places-hyd-100KMs">
          <br>
          <h3 class="terms">Tourist places around Hyderabad within 100 KMs</h3>
          <li>Yadgirigutta Narasimha Swamy Temple</li>
          <li>Surendrapuri</li>
          <li>Wargal Saraswathi Temple </li>
          <li>Sanghi Temple</li>
          <li>Ramoji Film City</li>
          <li>Chilkur Balaji</li>
          <li>Samatha Murthy - Statu of Equality</li>
          <li>Birla Mandir</li>
          <li>Peddamma Temple</li>
          <li>Jain Temples</li>
          <li>Gandipet Lake</li>
          <li>Ananthagiri Hills</li>
          <li>Tank Band</li>
          <li>Hussain Sagar</li>
          <li>Lumbini Park</li>
          <li>NTR Gardens</li>
          <li>Golconda Fort</li>
          <li>Charminar</li>
          <li>Durgam Cheruvu Cable Bridge</li>
        </div>
        <br><br>
        <div class="col-lg-12 Tourist-places-hyd-100KMs">
          <br>
          <h3 class="terms">Amusement Parks in and Around Hyderabad</h3>
          <li>Wonderla</li>
          <li>Water World</li>
          <li>Snow World</li>
          <li>Ramoji Film City</li>
          <li>Salajung Musume</li>

        </div>
        <br><br>
        <div class="col-lg-12 Tourist-places-hyd-100KMs">
          <br>
          <h3 class="terms">Resorts in and around Hyderabad</h3>
          <li>Golkonda Resorts</li>
          
        </div>

        <br><br>
        <div class="col-lg-12 Tourist-places-hyd-100KMs">
          <br>
          <h3 class="terms">Tourist places around Hyderabad within 200 KMs</h3>
          <li>Nagarjuna Sagar</li>
          <li>Warangal</li>
          <li>Ananthagiri Hills</li>
          <li>Srisailam</li>
          <li>Buddhavanam</li>
          
        </div>

        <br><br>
        <div class="col-lg-12 Tourist-places-hyd-100KMs">
          <br>
          <h3 class="terms">Hotels in and around Hyderabad</h3>
          <li>Falaknuma Palace</li>
          
        </div>

        <br><br>
        <div class="col-lg-12 Tourist-places-hyd-100KMs">
          <br>
          <h3 class="terms">Parks in and around Hyderabad</h3>
          <li>Zoo Park</li>
          <li>Oxygen Park</li>
          <li>Nehru Zoological Park</li>
          
        </div>

        <br><br>
        <div class="col-lg-12 Tourist-places-hyd-100KMs">
          <br>
          <h3 class="terms">Cycling Places</h3>
          <li>Paala Pitta Cycling Park</li>
          
        </div>

        <br><br>
        <div class="col-lg-12 Tourist-places-hyd-100KMs">
          <br>
          <h3 class="terms">Golf</h3>
          <li>Hyderabad Golf Club</li>
          
        </div>

        <br><br>
        <div class="col-lg-12 Tourist-places-hyd-100KMs">
          <br>
          <h3 class="terms">Horse Race</h3>
          <li>Hyderabad Race Club</li>
          
        </div>

        <br><br>
        <div class="col-lg-12 Tourist-places-hyd-100KMs">
          <br>
          <h3 class="terms">Grounds In Hyderabad</h3>
          <li>Rajiv Gandhi International Cricket Stadium</li>
          <li>LB Stadium</li>
          
        </div>

        <br><br>
        <div class="col-lg-12 Tourist-places-hyd-100KMs">
          <br>
          <h3 class="terms">Clubs</h3>
          <li>Secunderabad Clubs</li>
          <li>Gymkhana</li>
          
        </div>

        <br><br>
        <div class="col-lg-12 Tourist-places-hyd-100KMs">
          <br>
          <h3 class="terms">Pubs in Hyderabad</h3>
          <li>SkyHy Terrance Lounge</li>
          <li>Over the Moon</li>
          <li>Komatose</li>
          <li>Hard Rock Café</li>
          <li>Over The Moon</li>
          
        </div>

        <br><br>
        <div class="col-lg-12 Tourist-places-hyd-100KMs">
          <br>
          <h3 class="terms">Sports</h3>
          
        </div>

      </div>
    </div>
  </section>