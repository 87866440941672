<section id="payment-content">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 main-headetails">
        <span><strong>Passenger Details</strong></span>
        <span class="pull-right"><strong>Booking Details</strong></span>
        <hr>
      </div>
      <div class="col-lg-8">
        <div class="form-group required row">
          <label class="col-sm-2 control-label col-form-label">Name</label>
          <div class="col-sm-4">
            <input type="text" class="form-control" placeholder="Enter Name">
          </div>
          <label class="col-sm-2 control-label required col-form-label">Email</label>
          <div class="col-sm-4">
            <input type="email" class="form-control" placeholder="Enter Email">
          </div>
          <label class="col-sm-2 control-label required col-form-label" for="gender">Gender</label>
          <div class="col-sm-4 rado-div">
            <!-- <legend class="col-form-label col-sm-2 pt-0">Radios</legend> -->
            <label class="form-check-label" for="inlineRadio1">Male</label>
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
            <label class="form-check-label" for="inlineRadio2">Female</label>
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
          </div>
          <label class="col-sm-2 control-label required col-form-label">Age</label>
          <div class="col-sm-4">
            <input type="text" class="form-control"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="3"
              aria-describedby="passwordHelpInline" placeholder="Enter Age">
          </div>
          <label class="col-sm-2 control-label required col-form-label">Mobile</label>
          <div class="input-group col-sm-4">
            <div class="input-group-prepend">
              <div class="input-group-text">91</div>
            </div>
            <input type="text" class="form-control input-group-attached"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10"
              aria-describedby="passwordHelpInline" placeholder="Enter Mobile Number">
          </div>
          <label class="col-sm-2 control-label required col-form-label">AltNumber</label>
          <div class="input-group col-sm-4">
            <div class="input-group-prepend">
              <div class="input-group-text">91</div>
            </div>
            <input type="text" class="form-control input-group-attached"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10"
              aria-describedby="passwordHelpInline" placeholder="Enter Alternative Number">
          </div>
          <label class="col-sm-2 control-label required col-form-label">Address </label>
          <div class="col-sm-4">
            <input type="text" class="form-control" aria-describedby="passwordHelpInline" placeholder="Enter Address">
          </div>
          <label class="col-sm-2 control-label required col-form-label">ID Type</label>
          <div class="col-sm-4">
            <select id="" class="form-control" aria-placeholder="Sellect Id Type">
              <option>Aadhar Card</option>
              <option>Voter ID Card</option>
              <option>Driving license</option>
              <option>PAN Card</option>
              <option>Ration Card</option>
              <option>Passport</option>
            </select>
          </div>
          <label class="col-sm-2 control-label required col-form-label">ID Number </label>
          <div class="col-sm-4">
            <input type="text" class="form-control" aria-describedby="passwordHelpInline" placeholder="Enter ID Number">
          </div>

        </div>
      </div>
      <div class="col-lg-4 route-details">
        <span><strong>Kukkatpally To Ameerpet</strong></span>
        <hr style="margin: 0px;">
        <span><strong>Monday,20-06-2016</strong></span>
        <br>
        <span>Vehicle :</span><span><strong> Monday,20-06-2016</strong></span>
        <br>
        <span>Booking Id :</span><span><strong> 123</strong></span>
        <br>
        <span>Vehicle No :</span><span><strong> VC00123</strong></span>
        <br>
        <span>Driver Name :</span><span><strong> Sai</strong> <img src="../assets/images/testmonial-img.jpg"
            alt=""></span>
        <br>
        <span>Drive No :</span><span><strong> 8888888888</strong></span>
        <br>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-10">
        <span class="main-headetails"><strong>Offer / Coupon Details</strong></span>
        <hr>
      </div>
      <div class="col-lg-10">
        <div class="form-group row">
          <label class="col-sm-3 control-label col-form-label">I have a Coupon Code</label>
          <div class="col-sm-4">
            <input type="text" class="form-control" placeholder="Enter Coupon Code">
          </div>
          <button class="col-sm-2 wallet-butn">Apply</button>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label required col-form-label">Wallet Recharge</label>
          <div class="col-sm-4">
            <input type="text" class="form-control" placeholder="Recharge Wallet">
          </div>
          <button class="col-sm-2 wallet-butn">Apply</button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-10 main-headetails">
        <span><strong>Payment Details</strong></span>
        <span class="pull-right"><strong>Payment Summary</strong></span>
        <hr>
      </div>
      <div class="col-lg-8">
        <div class="form-row">
          <div class="form-group">
            <label class="control-label col-form-label" style="padding: 6px 15px 0px 0px;">Payment Type</label>
          </div>
          <div class="form-group">
            <select id="" class="form-control" aria-placeholder="Sellect Id Type">
              <option>Select Payment Type</option>
              <option>Wallets</option>
              <option>Cards</option>
            </select>
          </div>
        </div>
        <div class="row wallet-box">
          <div class="col-md-2 wallet-tabs">
            <ul class="nav nav-pills flex-column" id="myTab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#debit" role="tab" aria-controls="home"
                  aria-selected="true">Debit Card</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#credit" role="tab" aria-controls="profile"
                  aria-selected="false">Credit Card</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab"
                  aria-controls="contact" aria-selected="false">Net Banking</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="contact-tab" data-toggle="tab" href="#valourWallet" role="tab"
                  aria-controls="contact" aria-selected="false">Valour Wallet</a>
              </li>
            </ul>
          </div>
          <!-- /.col-md-4 -->
          <div class="col-md-10">
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="debit" role="tabpanel" aria-labelledby="home-tab">
                <span class="span-nfare"><strong>Enter Debit Card Details</strong></span>
                <span class="pull-right span-fare"><strong>Amount To Be Paid Rs. 1480.40</strong></span>
                <br>

                <form action="" class="deb-box">
                  <div class="form-group">
                    <table class="wallet-table">
                      <tr>
                        <td class="wall-label">
                          <label class=" control-label" for="colFormLabelSm">select card type</label>
                        </td>
                        <td>
                          <input type="radio" class="form-control">
                        </td>
                        <td>
                          <img src="../assets/images/accepted_c22e0.png" alt="">
                        </td>
                      </tr>
                    </table>
                  </div>

                  <div class="form-group wallet-form required row" id="wallet-card-form">
                    <label class="col-sm-4 control-label col-form-label" for="colFormLabelSm">Name on Card</label>
                    <div class="col-sm-6">
                      <input type="text" class="form-control" placeholder="Card Holder's Name">
                    </div>
                    <label class="col-sm-4 control-label required col-form-label" for="colFormLabelSm">Card Number</label>
                    <div class="col-sm-6">
                      <input type="text" class="form-control" placeholder="Debit/Credit Card Number">
                    </div>
                    <label class="col-sm-4 control-label col-form-label">Expiry Date</label>
                    <div class="col-sm-3">
                      <select class="form-control">
                        <option ng-selected="true" value="" selected="selected">Month</option>
                        <option value="01">Jan (01)</option>
                        <option value="02">Feb (02)</option>
                        <option value="03">Mar (03)</option>
                        <option value="04">Apr (04)</option>
                        <option value="05">May (05)</option>
                        <option value="06">June (06)</option>
                        <option value="07">July (07)</option>
                        <option value="08">Aug (08)</option>
                        <option value="09">Sep (09)</option>
                        <option value="10">Oct (10)</option>
                        <option value="11">Nov (11)</option>
                        <option value="12">Dec (12)</option>
                      </select>
                    </div>
                    <div class="col-sm-3">
                      <select class="form-control">
                        <option ng-selected="true" value="" selected="selected">Year</option>
                        <option value="20">2020</option>
                        <option value="21">2021</option>
                        <option value="22">2022</option>
                        <option value="23">2023</option>
                        <option value="24">2024</option>
                        <option value="25">2025</option>
                        <option value="26">2026</option>
                        <option value="27">2027</option>
                        <option value="28">2028</option>
                        <option value="29">2029</option>
                        <option value="30">2030</option>
                      </select>
                    </div>
                    <label class="col-sm-4 control-label required col-form-label">Card CVV</label>
                    <div class="col-sm-6">
                      <input type="text" class="form-control" placeholder="Enter CVV"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        maxlength="3">

                    </div>
                    <a class="col-sm-2" href=""> what is this?</a>

                    <div class="form-check col-sm-12 wallet-check">
                      <input type="checkbox" class="form-check-input" id="exampleCheck1">
                      <label class="form-check-label" for="exampleCheck1">Save your card</label>
                    </div>
                  </div>
                </form>
              </div>
              <div class="tab-pane fade" id="credit" role="tabpanel" aria-labelledby="profile-tab">
                <span><strong>Enter Debit Card Details</strong></span>
                <span class="pull-right span-fare"><strong>Amount To Be Paid Rs. 1480.40</strong></span>
                <br>

                <form action="" class="deb-box">
                  <div class="form-group">
                    <table class="wallet-table">
                      <tr>
                        <td class="wall-label">
                          <label class=" control-label">select card type</label>
                        </td>
                        <td>
                          <input type="radio" class="form-control">
                        </td>
                        <td>
                          <img src="../assets/images/accepted_c22e0.png" alt="">
                        </td>
                      </tr>
                    </table>
                  </div>

                  <div class="form-group wallet-form required row" id="wallet-card-form">
                    <label class="col-sm-4 control-label col-form-label">Name on Card</label>
                    <div class="col-sm-6">
                      <input type="text" class="form-control" placeholder="Card Holder's Name">
                    </div>
                    <label class="col-sm-4 control-label required col-form-label">Card Number</label>
                    <div class="col-sm-6">
                      <input type="text" class="form-control" placeholder="Debit/Credit Card Number">
                    </div>
                    <label class="col-sm-4 control-label col-form-label">Expiry Date</label>
                    <div class="col-sm-3">
                      <select class="form-control">
                        <option ng-selected="true" value="" selected="selected">Month</option>
                        <option value="01">Jan (01)</option>
                        <option value="02">Feb (02)</option>
                        <option value="03">Mar (03)</option>
                        <option value="04">Apr (04)</option>
                        <option value="05">May (05)</option>
                        <option value="06">June (06)</option>
                        <option value="07">July (07)</option>
                        <option value="08">Aug (08)</option>
                        <option value="09">Sep (09)</option>
                        <option value="10">Oct (10)</option>
                        <option value="11">Nov (11)</option>
                        <option value="12">Dec (12)</option>
                      </select>
                    </div>
                    <div class="col-sm-3">
                      <select class="form-control">
                        <option ng-selected="true" value="" selected="selected">Year</option>
                        <option value="20">2020</option>
                        <option value="21">2021</option>
                        <option value="22">2022</option>
                        <option value="23">2023</option>
                        <option value="24">2024</option>
                        <option value="25">2025</option>
                        <option value="26">2026</option>
                        <option value="27">2027</option>
                        <option value="28">2028</option>
                        <option value="29">2029</option>
                        <option value="30">2030</option>
                      </select>
                    </div>
                    <label class="col-sm-4 control-label required col-form-label">Card CVV</label>
                    <div class="col-sm-6">
                      <input type="text" class="form-control" placeholder="Enter CVV"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        maxlength="3">

                    </div>
                    <a class="col-sm-2" href=""> what is this?</a>

                    <div class="form-check col-sm-12 wallet-check">
                      <input type="checkbox" class="form-check-input" id="exampleCheck1">
                      <label class="form-check-label" for="exampleCheck1">Save your card</label>
                    </div>
                  </div>
                </form>
              </div>
              <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                <div id="otherNBDiv">
                  <div class="flashGrey" style="margin-top: 20px; padding-left: 34%;">
                    <span class="amtTxt">Amount Payable&nbsp;:</span> <span class="amtVal"><span>Rs.</span><span
                        class="grandTotalFare">200/-</span></span>
                  </div>
                  <label class="W30 TextRight inBlock BMargin pad15" for="DEXPMONTH"><strong>Select
                      From Popular Banks</strong></label>
                  <div class="width100">
                    <label class="axis">
                      <input type="radio" name="netBankName" required="" ng-model="net.Bankcode" value="AXIB" class=""
                        checked></label> <label class="icici"><input type="radio" name="netBankName" required=""
                        ng-model="net.Bankcode" value="ICIB" class=""></label> <label class="hdfc"><input type="radio"
                        name="netBankName" required="" ng-model="net.Bankcode" value="HDFB" class=""></label>
                    <label class="sbi"><input type="radio" name="netBankName" required="" ng-model="net.Bankcode"
                        value="SBIB" class=""></label> <label class="citi"><input type="radio" name="netBankName"
                        required="" ng-model="net.Bankcode" value="CITNB"
                        class="ng-pristine ng-untouched ng-invalid ng-invalid-required"></label>
                    <label class="ind"><input type="radio" name="netBankName" required="" ng-model="net.Bankcode"
                        value="INDB" class=""></label>
                  </div>
                  <label class="W30 TextRight inBlock BMargin pad15" for="DEXPMONTH"><strong>Select your
                      Bank</strong></label>
                  <div class="selectStyleBox col-sm-8 padding-five">
                    <select id="net.Bankcode" class="form-control" ng-model="net.Bankcode" name="netBankName">
                      <option value="" selected="">---- Select your bank ----</option>
                      <optgroup label="Top banks" class="optStyle">
                        <option value="AXIB">Axis Bank</option>
                        <option value="CITNB">Citi Bank</option>
                        <option value="HDFB">HDFC Bank</option>
                        <option value="ICIB">ICICI Bank</option>
                        <option value="SBIB">State Bank of India (SBI)</option>
                      </optgroup>
                      <optgroup label="All banks" class="optStyle">
                        <option value="AXIB">Axis Bank</option>
                        <option value="ADBB">Andhra Bank</option>
                        <option value="BOIB">Bank of India</option>
                        <option value="BOMB">Bank of Maharashtra</option>
                        <option value="CBIB">Central Bank Of India</option>
                        <option value="CRPB">Corporation Bank</option>
                        <option value="DCBB">Development Credit Bank</option>
                        <option value="FEDB">Federal Bank</option>
                        <option value="HDFB">HDFC Bank</option>
                        <option value="ICIB">ICICI Netbanking</option>
                        <option value="IDBB">Industrial Development Bank of
                          India</option>
                        <option value="INDB">Indian Bank</option>
                        <option value="INIB">IndusInd Bank</option>
                        <option value="INOB">Indian Overseas Bank</option>
                        <option value="JAKB">Jammu and Kashmir Bank</option>
                        <option value="KRKB">Karnataka Bank</option>
                        <option value="KRVB">Karur Vysya</option>
                        <option value="SBBJB">State Bank of Bikaner and
                          Jaipur</option>
                        <option value="SBHB">State Bank of Hyderabad</option>
                        <option value="SBIB">State Bank of India</option>
                        <option value="SBMB">State Bank of Mysore</option>
                        <option value="SBTB">State Bank of Travancore</option>
                        <option value="SOIB">South Indian Bank</option>
                        <option value="UBIB">Union Bank of India</option>
                        <option value="UNIB">United Bank Of India</option>
                        <option value="VJYB">Vijaya Bank</option>
                        <option value="YESB">Yes Bank</option>
                        <option value="CUBB">City Union</option>
                        <option value="CABB">Canara Bank</option>
                        <option value="SBPB">State Bank of Patiala</option>
                        <option value="DSHB">Deutsche Bank</option>
                        <option value="162B">Kotak Bank</option>
                        <option value="CSBN">Catholic Syrian Bank</option>
                        <option value="DCBCORP">DCB Bank - Corporate
                          Netbanking</option>
                        <option value="KRVB">Karur Vysya - Corporate
                          Netbanking</option>
                        <option value="PNBB">Punjab National Bank - Retail
                          Banking</option>
                        <option value="SRSWT">Saraswat Bank</option>
                        <option value="UBIBC">Union Bank - Corporate
                          Netbanking</option>
                        <option value="CPNB">Punjab National Bank-Corporate</option>
                        <option value="INGB">ING Vysya Bank</option>
                      </optgroup>
                    </select>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="valourWallet" role="tabpanel" aria-labelledby="valourWallet-tab">
                <p style="margin:auto;padding: 30px;">
                  To be Updated soon......
                </p>
               </div>      
            </div>
          </div>
          <!-- /.col-md-8 -->
        </div>
        
        <div class="text-center">
          <button class="btn walletbox-butn">PAY</button>
        </div>
      </div>
      <div class="col-lg-4 route-details">
        <table>
          <tbody>
            <tr>
              <td>
                <span>Fare :</span>
              </td>
              <td>
                <span><strong> Rs.1510</strong></span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Service Tax :</span>
              </td>
              <td>
                <span><strong> +Rs.7040</strong></span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Total Fare :</span>
              </td>
              <td>
                <span><strong> Rs.1510.60</strong></span>
              </td>
            </tr>
            <tr>
              <td>
                <span>coupon :</span>
              </td>
              <td>
                <span><strong> </strong></span>
              </td>
            </tr>
            <tr>
              <td><span><strong>Total Amount :</strong></span></td>
              <td> <span><strong> Rs.1510.60</strong></span></td>
            </tr>
          </tbody>
        </table>
        <span>Tranction of this site is safe and secure as indicated by the secure lock/ green colour in broswer address
          bar.</span>
        <br>
      </div>
    </div>
  </div>
</section>