<!-- <section id="result-content">
    <div class="container">
       <div class="row">
           <div class="col-md-12 modify-srch">
            <span class="pull-left modi-addres">{{splitPickupPlace}} <i class="fa fa-long-arrow-right" aria-hidden="true"></i> {{splitDropPlace}} {{date |  date:'medium'}}</span>
            <span class="pull-right modi-modi" data-toggle="collapse" data-target="#demo"> <i class="fa fa-plus" aria-hidden="true"></i> Modify Search</span>
           </div> 
       </div> 
       <div class="row">
        <div id="demo" class="collapse">
            <form class="locate">
                <div class="form-row">
                  <div class="form-group col-md-2 col-xs-12 col-sm-12">
                    <input type="text" class="form-control locate-text" placeholder="Pick Up Place">
                      <i class="fa fa-location-arrow" aria-hidden="true"></i>
                    </div>
                  <div class="form-group col-md-2 col-xs-12 col-sm-12">
                    <input type="text" class="form-control locate-text" placeholder="Dropping Place">
                      <i class="fa fa-map-marker" aria-hidden="true"></i>
                  </div>
                  <div class=" form-group col-md-2 col-xs-12 col-sm-12">
                    <input type="text" class="form-control locate-text" placeholder="Select Date" class="form-control locate-text calender" bsDatepicker>
                            <i class="fa fa-calendar"></i>
                  </div>
                  <div class="form-group col-md-2 col-xs-12 col-sm-12">
                    <select class="form-control locate-text" name="" id="">
                        <option value="">Vehicle Type</option>
                        <option value="">Desire</option>
                        <option value="">Innova</option>
                        <option value="">Tavera</option>
                    </select>
                  </div>
                  <div class="form-group col-md-2 col-xs-12 col-sm-12">
                    <select class="form-control locate-text" name="" id="">
                        
                        <option value="">No.of passengers</option>
                        <option value="">3</option>
                        <option value="">5</option>
                        <option value="">6</option>
                    </select>
                  </div>
                  <div class="form-group col-md-2 col-xs-12 col-sm-12">
                    <button class="btn modi-butn" type="submit">Search</button>
                  </div>
                </div>
              </form>
        </div>
       </div>
       <div class="row map-infrm">
           <div class="col-lg-7 col-md-12 col-xs-12 col-sm-12 mapp">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15225.71291175263!2d78.4358656203176!3d17.439206280255725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb90c55bb43183%3A0x1abc135b23ee2703!2sAmeerpet%2C%20Hyderabad%2C%20Telangana!5e0!3m2!1sen!2sin!4v1597324674701!5m2!1sen!2sin"  frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
           </div>
           <br>
           <div class="col-md-5 col-xs-12 col-sm-12 car-map">
            <h3>Basic Information</h3>
            <img src="../assets/images/verito.png" alt="">
            <p>Booking Id: <span><strong>VC1021</strong></span></p>
            <p>Pick Up Loaction: {{splitPickupPlace}}</p>
            <p>Drop Loaction: {{splitDropPlace}}</p>
            <p>Date & Time: <span><strong>{{date | date:'medium' }}</strong></span></p>
            <p>No of Passengers : {{noOfPersons}}</p>
            <p>Vehicle Type: {{vehicleType}}</p>
            <p>Package: <span><strong>1hr 15km 250Rs</strong></span></p>
            <p><input type="checkbox"> Airport Drop</p>
            <p>Estimated Fare: <span><strong>Rs 199</strong></span></p>
            <p><button class="btn pull-right" (click)="continue()" >Continue</button></p>
        </div>
        <div class="col-md-12 result-text">
         <h3>
            Cabs in Hyderabad
         </h3>
         <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sed voluptate nihil eum consectetur similique? Consectetur, quod, incidunt, harum nisi dolores delectus reprehenderit voluptatem perferendis dicta dolorem non blanditiis ex fugiat. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis, omnis doloremque non cum id reprehenderit, quisquam totam aspernatur tempora minima unde aliquid ea culpa sunt. Reiciendis quia dolorum ducimus unde. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sed voluptate nihil eum consectetur similique? Consectetur, quod, incidunt, harum nisi dolores delectus reprehenderit voluptatem perferendis dicta dolorem non blanditiis ex fugiat. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis, omnis doloremque non cum id reprehenderit, quisquam totam aspernatur tempora minima unde aliquid ea culpa sunt. Reiciendis quia dolorum ducimus unde</p>
        </div>
       </div>
    </div>
</section> -->

<section id="result-content">
  <div class="container">
    <div class="container-fluid">
      <div class="row car-box">
        <div class="col-lg-4 image-box">
          <div class="flex-container">
            <div>
              <img src="../assets/images/Savaari-Etios-Cab.jpg" alt="">
            </div>
            <div>
              <p class="veh-type">Toyota Etios</p>
              <!-- <br> -->
              <span class="eql-optn">or equalent</span>&nbsp;<i class="fa fa-info-circle image" aria-hidden="true"></i>
              <span class="tooltiptext2">Includes AC cabs like Amaze, Aspire, Dzire, Etios, Fiesta, Manza, Verito, Xcent or Zest</span>
            </div>
          </div>
        </div>
        <div class="col-lg-3 km-details">
          <div>
            <p class="include-kms">Includes 292 km</p>
            <p class="inner-details">Details <i class="fa fa-angle-down" aria-hidden="true"></i></p>
          </div>
        </div>
        <div class="col-lg-3 fare-details">
          <div class="flex-container1">
            <div>
              <p class="fare-type-strike"> &#x20b9;3754</p>
                <span class="rd-color">Save &#x20b9; 320</span>
            </div>
            <div>
              <p class="fare-type"> &#x20b9;3381</p>
                <span class="fare-type-span">Inclusive of GST</span>
            </div>
          </div>
        </div>
        <div class="col-lg-2 butn-div">
          <div>
          <button class="select-button" (click)="continue()" >Select</button>
        </div>
        </div>
      </div>
     <!-- ------------------------------------ -->
     <div class="row car-box">
      <div class="col-lg-4 image-box">
        <div class="flex-container">
          <div>
            <img src="../assets/images/Savaari-Ertiga-Cab.jpg" alt="">
          </div>
          <div>
            <p class="veh-type">Ertiga</p>
            <!-- <br> -->
            <span class="eql-optn">or equalent</span>&nbsp;<i class="fa fa-info-circle image" aria-hidden="true"></i>
            <span class="tooltiptext2">Includes AC Cabs like Ertiga, Xylo, Njoy, Evalia or Tavera</span>
          </div>
        </div>
      </div>
      <div class="col-lg-3 km-details">
        <div>
          <p class="include-kms">Includes 292 km</p>
          <p class="inner-details">Details <i class="fa fa-angle-down" aria-hidden="true"></i></p>
        </div>
      </div>
      <div class="col-lg-3 fare-details">
        <div class="flex-container1">
          <div>
            <p class="fare-type-strike"> &#x20b9;3754</p>
              <span class="rd-color">Save &#x20b9; 320</span>
          </div>
          <div>
            <p class="fare-type"> &#x20b9;3381</p>
              <span class="fare-type-span">Inclusive of GST</span>
          </div>
        </div>
      </div>
      <div class="col-lg-2 butn-div">
        <div>
        <button class="select-button" (click)="continue()">Select</button>
      </div>
      </div>
    </div>
    <!-- ----------------------------------------- -->
    <div class="row car-box">
      <div class="col-lg-4 image-box">
        <div class="flex-container">
          <div>
            <img src="../assets/images/Savaari-Innova-Cab.jpg" alt="">
          </div>
          <div>
            <p class="veh-type">Toyota Innova</p>
            <!-- <br> -->
            <span class="eql-optn">(6+1 seater)</span>&nbsp;<i class="fa fa-info-circle image" aria-hidden="true"></i>
            <span class="tooltiptext2">Includes AC cabs like Amaze, Aspire, Dzire, Etios, Fiesta, Manza, Verito, Xcent or Zest</span>
          </div>
        </div>
      </div>
      <div class="col-lg-3 km-details">
        <div>
          <p class="include-kms">Includes 292 km</p>
          <p class="inner-details">Details <i class="fa fa-angle-down" aria-hidden="true"></i></p>
        </div>
      </div>
      <div class="col-lg-3 fare-details">
        <div class="flex-container1">
          <div>
            <p class="fare-type-strike"> &#x20b9;3754</p>
              <span class="rd-color">Save &#x20b9; 320</span>
          </div>
          <div>
            <p class="fare-type"> &#x20b9;3381</p>
              <span class="fare-type-span">Inclusive of GST</span>
          </div>
        </div>
      </div>
      <div class="col-lg-2 butn-div">
        <div>
        <button class="select-button" (click)="continue()">Select</button>
      </div>
      </div>
    </div>
<!-- ------------------------------------------- -->
<div class="row car-box">
  <div class="col-lg-4 image-box">
    <div class="flex-container">
      <div>
        <img src="../assets/images/Savaari-Crysta-Cab.jpg" alt="">
      </div>
      <div>
        <p class="veh-type">Toyota Innova Crysta</p>
        <!-- <br> -->
        <span class="eql-optn">(7+1 seater)</span>&nbsp;<i class="fa fa-info-circle image" aria-hidden="true"></i>
        <span class="tooltiptext2">Includes AC cabs like Amaze, Aspire, Dzire, Etios, Fiesta, Manza, Verito, Xcent or Zest</span>
      </div>
    </div>
  </div>
  <div class="col-lg-3 km-details">
    <div>
      <p class="include-kms">Includes 292 km</p>
      <p class="inner-details">Details <i class="fa fa-angle-down" aria-hidden="true"></i></p>
    </div>
  </div>
  <div class="col-lg-3 fare-details">
    <div class="flex-container1">
      <div>
        <p class="fare-type-strike"> &#x20b9;3754</p>
          <span class="rd-color">Save &#x20b9; 320</span>
      </div>
      <div>
        <p class="fare-type"> &#x20b9;3381</p>
          <span class="fare-type-span">Inclusive of GST</span>
      </div>
    </div>
  </div>
  <div class="col-lg-2 butn-div">
    <div>
    <button class="select-button" (click)="continue()">Select</button>
  </div>
  </div>
</div>
<!-- ---------------------------- -->
<div class="row car-box">
  <div class="col-lg-4 image-box">
    <div class="flex-container">
      <div>
        <img src="../assets/images/Savaari-Tempo-Traveller-Cab.jpg" alt="">
      </div>
      <div>
        <p class="veh-type">Tempo Traveller</p>
        <!-- <br> -->
        <span class="eql-optn">(12+1 seater)</span>&nbsp;<i class="fa fa-info-circle image" aria-hidden="true"></i>
        <span class="tooltiptext2">Includes AC cabs like Amaze, Aspire, Dzire, Etios, Fiesta, Manza, Verito, Xcent or Zest</span>
      </div>
    </div>
  </div>
  <div class="col-lg-3 km-details">
    <div>
      <p class="include-kms">Includes 292 km</p>
      <p class="inner-details">Details <i class="fa fa-angle-down" aria-hidden="true"></i></p>
    </div>
  </div>
  <div class="col-lg-3 fare-details">
    <div class="flex-container1">
      <div>
        <p class="fare-type-strike"> &#x20b9;3754</p>
          <span class="rd-color">Save &#x20b9; 320</span>
      </div>
      <div>
        <p class="fare-type"> &#x20b9;3381</p>
          <span class="fare-type-span">Inclusive of GST</span>
      </div>
    </div>
  </div>
  <div class="col-lg-2 butn-div">
    <div>
    <button class="select-button" (click)="continue()" >Select</button>
  </div>
  </div>
</div>
<!-- --------------------------------- -->




    </div>
  </div>
</section>
