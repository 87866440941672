import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SwPush } from '@angular/service-worker';

// import { NgwWowService } from 'ngx-wow';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'Book a cab in Hyderabad |Rent Car in Hyderabad Yo Drives';
  // constructor(private router: Router,private wowService: NgwWowService) {
  //   this.wowService.init();
  constructor(private router: Router,swPush: SwPush) {
   }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }
}
