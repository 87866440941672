<section>
  <header>
      <div class="">
       <div class="infrm-top">
           <div class="container">
               <div class="row">
                   <div class="col-lg-12 nav-topp">
                       <div class="nav-top">
                           <ul class="navbar-right">
                             <!-- <a href="" id="callnowbutton"></a> -->
                               <!-- <li><a href="#" class="chatUs">Need Help? Write to us <i
                                       class="fa fa-commenting-o chat" aria-hidden="true"></i></a></li> -->
                               <li><a href="tel:9429690678" class=""><i class="fa fa-phone call"
                                       aria-hidden="true"></i> +91 9429690678,</a>
                                <a href="tel:8885255188" class=""> 8885255188</a></li>
                                <!-- <a href="tel:8885255144" class=""> 8885255144</a></li> -->
                                <!-- <li><a routerLink="/Login" routerLinkActive="active"><span>Login</span></a></li>
                               <li><a routerLink="Register" routerLinkActive="active"><span>Register</span></a></li> -->
                           </ul>
                       </div>
                   </div>   
           </div>
           </div>
       </div>
       
   <nav class="navbar navbar-expand-lg navbar-dark static-top" style="clear: both;">
       <div class="container">
         <a class="navbar-brand" href="#">
               <img src="../assets/images/yodrive-logo.png" alt="">
             </a>
         <button class="navbar-toggler" id="toggle-icon" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
               <span class="navbar-toggler-icon"></span>
             </button>
         <div class="collapse navbar-collapse" id="navbarResponsive">
           <ul class="navbar-nav ml-auto">
             <li class="nav-item">
               <a class="nav-link" routerLink="home" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse"> Home
                     <span class="sr-only">(current)</span>
                   </a>
             </li>
             <li class="nav-item">
              <a class="nav-link" routerLink="Car_Rental_Packages_With_Driver_and_Fuel" data-toggle="collapse" data-target=".navbar-collapse" routerLinkActive="active">Tariff</a>
             </li>
             <li class="nav-item">
              <a class="nav-link" routerLink="Services" routerLinkActive="active"  data-toggle="collapse" data-target=".navbar-collapse">Services</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="SelfDrive_tariff" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse">Self Drive</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="Fleet" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse">Fleet</a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" routerLink="AboutUs" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse">About Us</a>
              <! (click)="goToPage('about')" >
            </li> -->
            <li class="nav-item" >
              <a class="nav-link" routerLink="Payment" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse">Payment</a>
            </li>
             <li class="nav-item" >
               <a class="nav-link" routerLink="Contact" routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse">Contact Us</a>
             </li>
           </ul>
         </div>
       </div>
     </nav>
      </div>
   </header>
   <div class="modal fade" id="registerModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title" id="exampleModalLabel">Register</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form>
          <div class="modal-body">
            <div class="form-group">
              <input type="text" class="form-control" id="name1" aria-describedby="emailHelp" placeholder="Name">
              <!-- <small id="emailHelp" class="form-text text-muted">Your information is safe with us.</small> -->
            </div>
            <div class="form-group">
              <input type="email" class="form-control" id="email1" aria-describedby="emailHelp" placeholder="Email">
              <!-- <small id="emailHelp" class="form-text text-muted">Your information is safe with us.</small> -->
            </div>
            <div class="form-group">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text">+ 91</div>
                </div>
              <input type="number" class="form-control" id="password1" placeholder="Enter Your Mobile Number">
            </div>
          </div>
            <div class="form-group">
              <input type="password" class="form-control" id="password1" placeholder="Password">
            </div>
            <div class="form-group">
              <input type="password" class="form-control" id="password2" placeholder="Re-enter Password">
            </div>
          </div>
          <div class="modal-footer border-top-0 d-flex pull-right">
            <button type="submit" class="btn btn-success cancel">Cancel</button><button type="submit" class="btn btn-success register">Register</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal fade" id="registerModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title col-12 text-center" id="exampleModalLabel">SignUp For Yodrives Account</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6">
              <label class="social-label"><strong>Login with social accounts</strong></label>
              <button type="submit" class="btn btn-block btn-social btn-facebook"><span class="">
                <i class="fa fa-facebook" aria-hidden="true"></i></span> 
                &nbsp;<strong>Sign in with Facebook</strong>
                </button>
                <button type="submit" class="btn btn-block btn-social btn-google-plus"><span class="google-plus">
                  <i class="fa fa-google" aria-hidden="true"></i></span> 
                  &nbsp;<strong>Sign in with Google</strong></button>
            </div>
            <div class="col-lg-6">
              <label class="social-label"><strong>No Facebook?Sign Up via Email.</strong></label>
              <form action="">
                <div class="input-group mb-2 mr-sm-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text"><i class="fa fa-envelope-o" aria-hidden="true"></i></div>
                  </div>
                  <input type="email" class="form-control" id="inlineFormInputGroupUsername2" placeholder="Enter Email">
                </div>
                <div class="input-group mb-2 mr-sm-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text"><i class="fa fa-key" aria-hidden="true"></i></div>
                  </div>
                  <input type="password" class="form-control" id="inlineFormInputGroupUsername2" placeholder="Enter Password">
                </div>
                <div class="input-group mb-2 mr-sm-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text"><i class="fa fa-key" aria-hidden="true"></i></div>
                  </div>
                  <input type="password" class="form-control" id="inlineFormInputGroupUsername2" placeholder="Re-enter Password">
                </div>
                <div class="form-check mb-2 mr-sm-2">
                  <input class="form-check-input" type="checkbox" id="inlineFormCheck">
                  <label class="form-check-label span-text" for="inlineFormCheck">
                    By signing up,you agree to our <a href="">Terms and conditions</a>
                  </label>
                </div>
                <button type="submit" class="btn btn-primary login-btn">SIGNUP</button>
                <span class="span-text">Already have an Account?<a href="">Login</a></span>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>