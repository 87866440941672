import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../shared/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CountdownComponent } from 'ngx-countdown';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-hire-driver',
  templateUrl: './hire-driver.component.html',
  styleUrls: ['./hire-driver.component.css']
})
export class HireDriverComponent implements OnInit {

  @ViewChild('cd', { static: false })
  countdown: CountdownComponent;

  timeLeft: number;

  registrationForm: FormGroup;
  submitted = false;
  public min = new Date();
  doorNo = false;
  public geoCoder;
  location: any;
  latitude: any;
  longitude: any;
  public splitPickupPlace: any;
  public splitDropPlace: any;
  public vehicleType: any;
  public noOfPersons: any;
  public date: any;
  public verifyBtn: boolean = true;
  public otp: boolean = false;
  public isValid: boolean = true;
  public resendOtp = false;
  public mobile: boolean = false;
  public showTime:boolean;
  public verifyOtp:boolean=true;


  @ViewChild('searchPlaces', { static: false })
  public searchElementRef1: ElementRef;

  counter$: Observable<number>;
  count = 120;



  constructor(private formBuilder: FormBuilder, private commonService: CommonService, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private toastr: ToastrService, private router: Router, private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {

    this.registrationForm = this.formBuilder.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      purpose:['',Validators.required],
      date:['',Validators.required],
      noOfDays:['',Validators.required],
      mobileNo: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      otp: ['', Validators.required]
    });

  }

  get f() { return this.registrationForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.SpinnerService.show();

    let phoneno = /^\d{10}$/;

    if(!this.registrationForm.value.mobileNo.match(phoneno))
    {
      this.SpinnerService.hide();
      return;
    }

    if (this.registrationForm.value.mobileNo == null || this.registrationForm.value.mobileNo == '' || this.registrationForm.value.mobileNo == undefined || this.registrationForm.value.name == null || this.registrationForm.value.name == '' || this.registrationForm.value.name == undefined) {
      this.SpinnerService.hide();
      return;
    }

    localStorage.setItem('name', this.registrationForm.value.name);

    if (this.registrationForm.value.mobileNo != null && this.registrationForm.value.mobileNo != '' && this.registrationForm.value.mobileNo != undefined && this.registrationForm.value.otp == "" || this.registrationForm.value.otp == undefined) {
      this.SpinnerService.show();
      this.showTime=true;
      this.timeLeft = 120;
      let interval;

      let body = { "taxiDriverName": this.registrationForm.value.name,
      "address": this.registrationForm.value.address,
      "purpose": this.registrationForm.value.purpose,
      "date": this.registrationForm.value.date,
      "noOfDays": this.registrationForm.value.noOfDays,
      "taxiDriverMobileNumber": this.registrationForm.value.mobileNo};

      this.commonService.addHireTaxiDriver(body).subscribe(response => {

        if(response.code==100 && response.success==true){

      interval = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
          if (this.timeLeft == 0) {
            this.resendOtp = true;
            this.showTime=false;
            this.otp=false;
          }
        } else {
          this.timeLeft = 0;
        }
      }, 1000);
      this.commonService.sendVerificationCodeToMobile(this.registrationForm.value.mobileNo).subscribe(response => {
        if (response != null) {
          this.verifyBtn = false;
          this.mobile = true;
          this.SpinnerService.hide();
          this.otp = true;
          this.toastr.success('Verification code has been sent to registered mobile.', 'Registration.');

        } else {
          this.verifyBtn = true;
          this.otp = false;
          this.SpinnerService.hide();
        }
      });
    }else
    {
      this.toastr.error(response.message, 'Registration.');
      this.SpinnerService.hide();
    }
    });
    } else if (this.registrationForm.value.otp != "" && this.registrationForm.value.otp != undefined && this.registrationForm.value.otp != null && this.isValid) {
      this.SpinnerService.show();
      this.commonService.verifyVerificationCodeToMobile(this.registrationForm.value.mobileNo, this.registrationForm.value.otp).subscribe(response => {
        if (response.successCode == 100) {
          this.SpinnerService.hide();
          this.showTime=false;
          this.otp = false;
          this.resendOtp=false;
          this.verifyOtp=false;
          this.toastr.success('OTP verified successfully.', 'Registration.');
          this.isValid = false;
        } else {
          this.toastr.error('Please enter correct OTP.', 'Registration.');
          this.verifyOtp=true;
          this.otp = true;
          this.SpinnerService.hide();
        }
      });
    }
    else {
      this.commonService.sendHireTaxiMail(this.registrationForm.value.mobileNo, this.registrationForm.value.name,this.registrationForm.value.address,this.registrationForm.value.purpose,this.registrationForm.value.date,this.registrationForm.value.noOfDays).subscribe(response => {
        this.SpinnerService.show();
        if (response.successCode == 100) {
          this.SpinnerService.hide();
          this.toastr.success('Mail sent successfully.', 'Registration.');
          this.router.navigate(['/confirm']);
          this.isValid = false;
          this.showTime=false;
          this.registrationForm.reset();
        } else {
          this.SpinnerService.hide();
          this.toastr.error('Mail not sent .', 'Registration');
        }
      });
    }

  }


  onSelect() {
    this.SpinnerService.show();
    let interval;
    this.timeLeft = 120;
    this.otp = true;
    this.showTime=true;
    this.resendOtp=false;
    interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        if (this.timeLeft == 0) {
          this.resendOtp = true;
          this.otp = false;
          this.showTime=false;
        }
      } else {
        this.timeLeft = 0;
      }
    }, 1000);
    this.commonService.sendVerificationCodeToMobile(this.registrationForm.value.mobileNo).subscribe(response => {
      if (response != null) {
        this.SpinnerService.hide();
        this.verifyBtn = false;
        this.otp = true;
        this.toastr.success('Verification code has been sent to registered mobile.', 'Registration');

      } else {
        this.verifyBtn = true;
        this.otp = false;
      }
    });
  }


}
