<footer id="footer" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>About Yo <span style="font-style: italic;">drives !</span></h4>
            <ul>
              <!-- <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a routerLink="home"> Home</a></li> -->
              <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a routerLink="AboutUs"> About us</a></li>
              <!-- <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a href="#">Services</a></li>
              <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a href="#">Tariff</a></li>
              <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a href="#">Offers</a></li> -->
              <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a routerLink="Contact">Contact Us</a></li>
              <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a routerLink="yodrives_privacy_policy" routerLinkActive="active">Privacy Policy</a></li>
              <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a routerLink="Terms_Conditions" routerLinkActive="active">Terms & Conditions</a></li>
              <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a routerLink="SelfDrive_terms_conditions"> Self Drive Terms and conditions</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Services</h4>
            <ul>
              <!-- <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a href="#">Cancellation Policy</a></li> -->
              <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a routerLink="SelfDrive_tariff" routerLinkActive="active">Self Drive</a></li>
              <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a routerLink="Blog" routerLinkActive="active">Blog</a></li>
              <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a routerLink="Fleet" routerLinkActive="active">Fleet</a></li>
              <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a routerLink="Car_Rental_Packages_With_Driver_and_Fuel" routerLinkActive="active">Tariff</a></li>
              <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a routerLink="Payment" routerLinkActive="active">Payment</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Explore</h4>
            <!-- <p>Bhukya Software Technologies Pvt Ltd <br>
              HIG-249,Sri Sai Nilayam,3rd Floor,Road No-2,KPHB Colony, Kukatpally, Hyderabad,500072<br>
            </p>
              <p class="phone-top"><strong>Phone :</strong> +91 888 5255 199
              <strong>Email :</strong> <a href="mailto:info@bhukyasofttech.com"> info@bhukyasofttech.com</a><br>
            </p> -->
            <ul>
              <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a routerLink="Luggage_Carrier" routerLinkActive="active"> Car Luggage Carrier</a></li>
                <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a routerLink="Contact" routerLinkActive="active">Corporate Tie Up's</a></li>
                <!-- <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a href="#">Tariff Responsibilities</a></li> -->
                <!-- <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a href="#">Gallery</a></li> -->
                <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a href="#">Gallery</a></li>
                <!-- <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a [routerLink]="['/taxi_driver_registration']" >Taxi Driver Registration</a></li> -->
                <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a [routerLink]="['/taxidriver_registration']" >Taxi Driver Registration</a></li>
                <li><i class="fa fa-angle-right" aria-hidden="true"></i> <a [routerLink]="['/hire_driver']" >Hire Taxi Driver</a></li>
              </ul>

          </div>

          <div class="col-lg-3 col-md-6 footer-info">
            <h4>Follow Us</h4>
            <div class="social-links" style="float:left">
              <!-- <a href="#" target="_blank" class="twitter"><i class="fa fa-twitter" aria-hidden="true"></i></a> -->
              <a href="https://www.facebook.com/YoDrives" class="facebook" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>
              <a href="https://g.page/yodrives?gm" class="facebook" target="_blank"> <img class="google-soc" src="../assets/images/google.png" alt=""> </a>

              <a href="https://wa.me/message/52XWF5WDQBXSK1" target="_blank"><i class="fa fa-whatsapp" aria-hidden="true"></i></a>

              <a href="https://www.linkedin.com/company/yodrives"  target="_blank" class="linkedin"><i class="fa fa-linkedin" aria-hidden="true"></i></a>

            </div>
            <div class="social-links social1">
              <a  href="https://www.instagram.com/yodrives/" target="_blank" data-toggle="tooltip"><i class="fa fa-instagram" aria-hidden="true"></i></a>

              <a  href="https://www.youtube.com/channel/UCnfKr8g2FbKfg5rU_YsW2Nw" target="_blank"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
              <a  href="https://twitter.com/YoDrives" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a>
              <a  href="#" target="_blank"><i class="fa fa-tumblr" aria-hidden="true"></i></a>

            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
         <span class="copy-left">Copyright &copy; 2020 - 2023, <span>Bhukya Tours and Travels.</span></span>
         <span class="power-right"> Powered by <a style="color:yellow;" href="https://bhukyasofttech.com/" target="_blank">Bhukyasofttech</a></span>
      </div>
    </div>
  </footer>
