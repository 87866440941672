import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { FleetComponent } from './fleet/fleet.component';
import { ServicesComponent} from './services/services.component'
import { ContactComponent } from './contact/contact.component';
import { ResultComponent } from './result/result.component';
import { DetailsPaymentComponent } from './details-payment/details-payment.component';
import { BookingComponent } from './booking/booking.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SelfDriveComponent } from './SelfDrive_tariff/self-drive.component';
import { SelfDriveTermsAndConditionsComponent } from './self-drive-terms-and-conditions/self-drive-terms-and-conditions.component';
import { CarRentalPackagesWithDriverAndFuelComponent } from './car-rental-packages-with-driver-and-fuel/car-rental-packages-with-driver-and-fuel.component'; 
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { HireDriverComponent } from './hire-driver/hire-driver.component';
import { TaxiDriverRegistrationComponent } from './taxi-driver-registration/taxi-driver-registration.component';
import { Covid19Component } from './covid19/covid19.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component'; 
import { TestingsampleComponent } from './testingsample/testingsample.component'; 
import { LuggageCarrierRentComponent } from './luggage-carrier-rent/luggage-carrier-rent.component';
import { PaymentComponent } from './payment/payment.component';
import { BlogComponent } from './blog/blog.component';
import { SelfDriveBookingComponent } from './self-drive-booking/self-drive-booking.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'home' , component: HomeComponent },
  { path: 'AboutUs' , component: AboutComponent },
  { path: 'Fleet' , component: FleetComponent },
  { path: 'Services' , component: ServicesComponent },
  { path: 'Contact' , component: ContactComponent },
  { path: 'result' , component:ResultComponent },
  { path: 'details' , component: DetailsPaymentComponent },
  { path: 'booking' , component: BookingComponent },
  { path: 'confirm' , component: ConfirmationComponent},
  { path: 'yodrives_privacy_policy' , component : PrivacyPolicyComponent},
  { path: 'SelfDrive_tariff' , component : SelfDriveComponent},
  { path: 'Car_Rental_Packages_With_Driver_and_Fuel' , component : CarRentalPackagesWithDriverAndFuelComponent},
  { path: 'Terms_Conditions' , component : TermsConditionsComponent},
  { path: 'privacy' , component : PrivacyPolicyComponent},
  { path: 'SelfDrive' , component : SelfDriveComponent},
  { path: 'SelfDrive_terms_conditions' , component : SelfDriveTermsAndConditionsComponent},
  { path: 'Blog' , component : BlogComponent},
  { path: 'tariff' , component : CarRentalPackagesWithDriverAndFuelComponent},
  { path: 'hire_driver' , component: HireDriverComponent},
  { path: 'taxidriver_registration' , component:TaxiDriverRegistrationComponent },
  { path: 'Covid19_Travel_Guidelines' , component:Covid19Component },
  { path: 'Register' , component:RegisterComponent },
  { path: 'Login' , component:LoginComponent },
  { path: 'Forgot_Password' , component:ForgotPasswordComponent },
  { path: 'Luggage_Carrier' , component: LuggageCarrierRentComponent },
  { path: 'Payment' , component:PaymentComponent },
  { path: 'Testing' , component:TestingsampleComponent },
  { path: 'SelfDriveBooking' , component:SelfDriveBookingComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
