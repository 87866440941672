<section id="booking-content">
    <div class="container">
        <div class="row body-pad-btm">
            <div class="col-lg-6 panel-border">
                <div class="booking-form">
                    <form [formGroup]="selfdrivebookingForm" (ngSubmit)="onSubmit()">
                        <p class="panel-head"><b>1. Contact & Pickup Details</b></p>
                        <div class="form-group">
                          <input type="text" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }"  class="form-control form-control-sm"  aria-describedby="emailHelp" maxlength="50" placeholder="Enter Name">
    
                          <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Name is required</div>
                        </div>
                        </div>
                        <div class="form-group">
                            <input type="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control form-control-sm" id="exampleInputEmail1"  maxlength="60" aria-describedby="emailHelp" placeholder="Enter Email">
    
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                            </div>
    
                          </div>
                        <div class="form-group">
                            <div class="input-group mb-2">
                                <div class="input-group-prepend">
                                  <div class="input-group-text form-control-sm">+91 India</div>
                                </div>
                                <input type="text" [attr.disabled]="mobile ? '' : null" formControlName="mobileNo" [ngClass]="{ 'is-invalid': submitted && f.mobileNo.errors }"  class="form-control form-control-sm" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10" placeholder="Enter Mobile No">
                                <div *ngIf="submitted && f.mobileNo.errors" class="invalid-feedback">
                                    <div *ngIf="f.mobileNo.errors.required">Mobile no is required</div>
                                    <div *ngIf="f.mobileNo.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
                                </div> 
                            </div>
                        </div>
                        <div class="form-group form-group-pad-btm">
                            <button class="otp-btn" type="submit" *ngIf="verifyBtn">Generate OTP</button>
                        </div>
                         <div *ngIf="otp" class="form-group">
                            <input type="otp" formControlName="otp" class="form-control form-control-sm" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  maxlength="6" aria-describedby="emailHelp" placeholder="Enter OTP">
    
                            <!-- <div *ngIf="submitted" class="invalid-feedback">
                                <div>OTP verified successfully.</div>
                            </div> -->
    
                            <small *ngIf="showTime" class="verify-code">We have sent the verification code {{timeLeft}} Seconds Left....</small> 
                            <br>
                            <button *ngIf="verifyOtp" class="otp-btn" type="submit">Verify OTP</button>
                        </div>
                        <p *ngIf="resendOtp">Not received your code? <a class="w3-button w3-black" (click)="onSelect()" >Resend Code</a></p>
    
    
                        <!-- <div class="form-group">
                            <div class="input-group mb-2">
                                <div class="input-group-prepend">
                                  <div class="input-group-text form-control-sm"><img src="../assets/images/location.ico" alt=""></div>
                                </div>
                                <input type="text" formControlName="location" [ngClass]="{ 'is-invalid': submitted && f.location.errors }"  class="form-control form-control-sm" placeholder="Enter Location" (keydown.enter)="$event.preventDefault()" autocorrect="on" autocapitalize="on" spellcheck="on" #searchPlaces >
    
                                <div *ngIf="submitted && f.location.errors" class="invalid-feedback">
                                    <div *ngIf="f.location.errors.required">Location is required</div>
                                </div> 
    
                            </div>
    
                            <div class="input-group mb-2" *ngIf="doorNo">
                                <input type="text" class="form-control form-control-sm" placeholder="Landmark/Door Number/Building Name" >
                            </div>
                            
                        </div> -->
                        <!-- <div class="form-group">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" id="disabledFieldsetCheck">
                              Send me trip updates on WhatsApp <i class="fa fa-info-circle image" aria-hidden="true"></i>
                              <span class="tooltiptext2">Key information like itinerary, Driver & Payment details </span>
                            </div>
                        </div> -->
                        <div class="form-group">
                            <button type="submit" [disabled]="isValid" class=" panel-butn pull-right">Submit</button>
                        </div>
                    </form>
                </div>
            </div> 
            <div class="col-lg-5 payment-border">
                <!-- <div>
                    <p class="panel-head">
                        Yo drive's Promise of Quality
                     </p>
                     <div class="since-image">
                    </div>
                    <div class="driver-image">
                        <a href="#"></a>
                    </div>
                    <div class="rating-image">
                        <a href="#"></a>
                    </div>
                </div> -->
                <div class="booking-panel">
                    <p class="panel-head">                        
                    <b>2. Your Self Driving Booking Details</b>
                     </p>
                     <table data-toggle="table" data-striped="true" class="text-center">
                        <tbody>
                        <tr>
                            <td><b>Itinerary :</b> </td>
                            <td> <b>{{splitPickupPlace}}</b>
                        </tr>
                        <tr>
                            <td><b>Start Time :</b> </td>
                            <td> <b>{{pickdate}}</b></td>
                        </tr>
                        <tr>
                            <td><b>End Time :</b> </td>
                            <td> <b>{{enddate}}</b></td>
                        </tr>
                        <tr>
                            <td><b>Vehicle Type :</b></td>
                            <td><b>{{vehicleType}}</b></td>
                        </tr>
                        <!-- <tr>
                            <td>Total Fare : </td>
                            <td>₹ 2324</td>
                        </tr> -->
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</section>
<!-- <div class="form-group">
    <button type="submit" class="btn panel-butn pull-right form-control-sm">Proceed</button>
    <input type="button" class="btn panel-butn pull-right form-control-sm" value="Proceed">
</div> -->