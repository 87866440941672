<section id="covid-component">
    <div class="main-head">
        <p>COVID-19 Travel Guidelines</p>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h4>IMPORTANT : ADHERE TO STATE GUIDELINES</h4>
                <p>States have released their travel guidelines for inbound and outbound travellers vis-a-vis passes, permits, quarantine rules and other requirements. Please go through the guidelines of your source and destination state carefully before planning your travel and have a hassle-free experience.</p>
                <hr>
                <p> <strong>Note:</strong> These are the latest guidelines available and are subject to change. Passengers are requested to check for all latest updates from relevant authorities and ensure adherence to the same.
                </p>
                <h5>General Rules for travel across the country</h5>
                <ul>
                    <li>All passengers are advised to download the Aarogya Setu app on their mobile phones.</li>
                    <li>All passengers are required to use face covers/masks, and are required to follow hand hygiene, respiratory hygiene, and environmental hygiene.</li>
                    <li>All travellers must follow social distancing measures at bus terminals.</li>
                    <li>Travelers with quarantine seal mark will not be allowed to board the bus.</li>
                </ul>
            </div>
        </div>
    </div>
</section>
