import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { CommonService } from '../shared/common.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, timer } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { CountdownComponent } from 'ngx-countdown';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})

export class BookingComponent implements OnInit {
  @ViewChild('cd', { static: false })
  countdown: CountdownComponent;

  timeLeft: number;

  bookingForm: FormGroup;
  submitted = false;
  doorNo = false;
  public geoCoder;
  location: any;
  latitude: any;
  longitude: any;
  public splitPickupPlace: any;
  public splitDropPlace: any;
  public vehicleType: any;
  public noOfPersons: any;
  public date: any;
  public verifyBtn: boolean = true;
  public otp: boolean = false;
  public isValid: boolean = true;
  public resendOtp = false;
  public mobile: boolean = false;
  public showTime:boolean;
  public verifyOtp:boolean=true;


  @ViewChild('searchPlaces', { static: false })
  public searchElementRef1: ElementRef;

  counter$: Observable<number>;
  count = 120;


  constructor(private formBuilder: FormBuilder, private commonService: CommonService, private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private toastr: ToastrService, private router: Router, private SpinnerService: NgxSpinnerService) {

    this.counter$ = timer(0, 1000).pipe(
      take(this.count),
      map(() => --this.count)
    );

  }

  ngOnInit(): void {

    this.bookingForm = this.formBuilder.group({
      name: ['', Validators.required],
      mobileNo: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      otp: ['', Validators.required]
    });




    // this.mapsAPILoader.load().then(() => {
    //   this.geoCoder = new google.maps.Geocoder;

    //   let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef1.nativeElement);
    //   autocomplete.addListener("place_changed", () => {
    //     this.ngZone.run(() => {
    //       //get the place result
    //       let place: google.maps.places.PlaceResult = autocomplete.getPlace();
    //       this.doorNo=true;

    //       this.location=place.formatted_address;
    //       //verify result
    //       if (place.geometry === undefined || place.geometry === null) {
    //         return;
    //       }

    //       //set latitude, longitude and zoom
    //        this.latitude = place.geometry.location.lat();
    //        this.longitude = place.geometry.location.lng();
    //     });
    //   });
    // });

    this.splitPickupPlace = localStorage.getItem('splitPickupPlace');
    this.splitDropPlace = localStorage.getItem('splitDropPlace');
    this.vehicleType = localStorage.getItem('vehicleType');
    this.noOfPersons = localStorage.getItem('noOfPersons');
    this.date = localStorage.getItem('date');

  }

  get f() { return this.bookingForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.SpinnerService.show();


    let phoneno = /^\d{10}$/;

    if(!this.bookingForm.value.mobileNo.match(phoneno))
    {
      this.SpinnerService.hide();
      return;
    }

    if (this.bookingForm.value.mobileNo == null || this.bookingForm.value.mobileNo == '' || this.bookingForm.value.mobileNo == undefined || this.bookingForm.value.name == null || this.bookingForm.value.name == '' || this.bookingForm.value.name == undefined) {
      this.SpinnerService.hide();
      return;
    }

    localStorage.setItem('name', this.bookingForm.value.name);

    if (this.bookingForm.value.mobileNo != null && this.bookingForm.value.mobileNo != '' && this.bookingForm.value.mobileNo != undefined && this.bookingForm.value.otp == "" || this.bookingForm.value.otp == undefined) {
      this.SpinnerService.show();
      this.showTime=true;
      this.timeLeft = 120;
      let interval;

      interval = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
          if (this.timeLeft == 0) {
            this.resendOtp = true;
            this.otp = false;
            this.showTime=false;
          }
        } else {
          this.timeLeft = 0;
        }
      }, 1000);
      this.commonService.sendVerificationCodeToMobile(this.bookingForm.value.mobileNo).subscribe(response => {
        if (response != null) {
          this.verifyBtn = false;
          this.mobile = true;
          this.SpinnerService.hide();
          this.otp = true;
          this.toastr.success('Verification code has been sent to registered mobile.', 'YODRIVES!');

        } else {
          this.verifyBtn = true;
          this.otp = false;
          this.SpinnerService.hide();
        }
      });
    } else if (this.bookingForm.value.otp != "" && this.bookingForm.value.otp != undefined && this.bookingForm.value.otp != null && this.isValid) {
      this.SpinnerService.show();
      this.commonService.verifyVerificationCodeToMobile(this.bookingForm.value.mobileNo, this.bookingForm.value.otp).subscribe(response => {
        if (response.successCode == 100) {
          this.SpinnerService.hide();
          this.showTime=false;
          this.resendOtp=false;
          this.verifyOtp=false;
          this.otp = false;
          this.toastr.success('OTP verified successfully.', 'YODRIVES!');
          this.isValid = false;
        } else {
          this.toastr.error('Please enter correct OTP.', 'YODRIVES!');
          this.SpinnerService.hide();
          this.verifyOtp=true;
          this.otp = true;
        }
      });
    }
    else {
      this.commonService.sendBookingMail(this.bookingForm.value.mobileNo, this.splitPickupPlace, this.splitDropPlace, this.vehicleType, this.date, this.bookingForm.value.name, this.noOfPersons).subscribe(response => {
        this.SpinnerService.show();
        if (response.successCode == 100) {
          this.SpinnerService.hide();
          this.toastr.success('Mail sent successfully.', 'YODRIVES!');
          this.router.navigate(['/confirm']);
          this.isValid = false;
          this.showTime=false;
          this.bookingForm.reset();
        } else {
          this.SpinnerService.hide();
          this.toastr.error('Mail not sent .', 'YODRIVES!');
        }
      });
    }

  }

  onSelect() {
    this.SpinnerService.show();
    this.otp = true;
    this.showTime=true;
    let interval;
    this.timeLeft = 120;
    this.resendOtp=false;
    interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        if (this.timeLeft == 0) {
          this.resendOtp = true;
          this.otp = false;
          this.showTime=false;
        }
      } else {
        this.timeLeft = 0;
      }
    }, 1000);
    this.commonService.sendVerificationCodeToMobile(this.bookingForm.value.mobileNo).subscribe(response => {
      if (response != null) {
        this.SpinnerService.hide();
        this.verifyBtn = false;
        this.otp = true;
        this.toastr.success('Verification code has been sent to registered mobile.', 'YODRIVES!');

      } else {
        this.verifyBtn = true;
        this.otp = false;
      }
    });
  }


}
