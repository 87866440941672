import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  title = 'Privacy Policy  | Car rental Hyderabad | book cabs online | yodrives.com';
  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'keywords', content: 'Hyderabad Cabs ,Car rentals Hyderabad ,sanitized cars hyderabad,sanitized cabs in hyderabad, Car Hire , Taxi India , book cabs , Cab online , Taxi Cabs , Call Cab , Taxi on Call , Airport Transfer , Travel Companies, car rental privacy policy,online-car-rental' },
      { name: 'author', content: 'yodrives - Bhukya Tours and Travels' },
      { name: 'keyphrase', content: 'Hyderabad Cabs ,Car rentals Hyderabad, Car Hire , Taxi India , book cabs , Cab online , Taxi Cabs , Call Cab , Taxi on Call , Airport Transfer , Travel Companies, online-car-rental' },
      { name: 'description', content: 'Useful articles on travel, tours, car hires, car rentals and taxi services. Interesting articles on travels, tours and cab experiences on yodrives.com' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);
  }

}
