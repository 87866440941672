import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.css']
  })
  export class BlogComponent implements OnInit {
  
    constructor() { }
  
    ngOnInit(): void {
    }
  
  }