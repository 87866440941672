<section id="fleet-content"> 
    <div class="main-head">
        <p>Our Fleet</p>
      </div>
<div class="container">
    <div class="sub-head">
        <h6>Our fleet comprises a wide range of brand new cars none of which is more than 3 years old. All necessary measures are taken to ensure that the cars are well-maintained and regularly updated and upgraded.</h6>
    </div>
        <div class="row border-line">
            <div class="col-md-3">
                <div class="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a class="nav-link mb-3 p-3 shadow active" id="v-pills-home-tab" data-toggle="tab" data-target="#show3" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                        <i class="fa fa-star-o mr-2" aria-hidden="true"></i>
                        <span class="font-weight-bold small text-uppercase">Mini</span></a>
                    <a class="nav-link mb-3 p-3 shadow" id="v-pills-profile-tab" data-toggle="tab" data-target="#show2" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                        <i class="fa fa-star-half mr-2" aria-hidden="true"></i>
                        <span class="font-weight-bold small text-uppercase">Sedan</span></a>
    
                    <a class="nav-link mb-3 p-3 shadow" id="v-pills-messages-tab" data-toggle="tab" data-target="#show1" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                        <i class="fa fa-star-half-o mr-2" aria-hidden="true"></i>
                        <span class="font-weight-bold small text-uppercase">Suv</span></a>
    
                    <a class="nav-link mb-3 p-3 shadow" id="v-pills-settings-tab" data-toggle="tab" data-target="#show" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                        <i class="fa fa-star mr-2" aria-hidden="true"></i>
                        <span class="font-weight-bold small text-uppercase">Xuv</span></a>
                    </div>
            </div>
    
    
            <div class="col-md-9">
                <div class="tab-content" id="v-pills-tabContent">
                    <div class="tab-pane fade shadow rounded show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                        <div class="bg-white">
                            <div id="slide1" class="carousel slide" data-ride="carousel">

                                <!-- Indicators -->
                                <ul class="carousel-indicators">
                                  <li data-target="#slide1" data-slide-to="0" class="active"></li>
                                  <li data-target="#slide1" data-slide-to="1"></li>
                                  <li data-target="#slide1" data-slide-to="2"></li>
                                </ul>
                              
                                <!-- The slideshow -->
                                <div class="carousel-inner">
                                  <div class="carousel-item active">
                                    <img src="../assets/images/swift.png" alt="Los Angeles">
                                  </div>
                                  <div class="carousel-item">
                                    <img src="../assets/images/indica.png" alt="Chicago">
                                  </div>
                                </div>
                              
                                <!-- Left and right controls -->
                                <a class="carousel-control-prev" href="#slide1" data-slide="prev">
                                  <span class="carousel-control-prev-icon"></span>
                                </a>
                                <a class="carousel-control-next" href="#slide1" data-slide="next">
                                  <span class="carousel-control-next-icon"></span>
                                </a>
                              
                              </div>
                        </div>
                        <!-- <div class="bg-grey pill-base">
                            <p class="text-muted mb-2 image-content">It's a regular air-conditioned hatchback gives you comfortable outstation ride experience and is the best economical option for a road trip with your family.</p>
                            <div class="row pill-icon">
                                <div class="col-lg-1 col-sm-6 col-xs-6 text-center">
                                    <img src="../assets/images/air-conditioner.png" alt="">
                                 <p>AC</p>
                                </div>
                                <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                                    <img src="../assets/images/smartphone-charger.png" alt="">
                                   <p>Mobile Charger</p>
                                </div>
                                <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                                    <img src="../assets/images/spray.png" alt="">
                                    <p>Sanitizer</p>   
                                </div>
                                   <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                                    <img src="../assets/images/seat.png" alt="">
                                    <p class="white-sp">4 Seats</p>   
                                </div>
                                   <div class="col-lg-1 text-center img-cap">
                                    <img src="../assets/images/gps.png" alt="">
                                    <p>GPS</p>   
                                </div>
                                   <div class="col-lg-1 text-center img-cap">
                                    <img src="../assets/images/first-aid-kit.png" alt="">
                                    <p>FirstAid Kit</p>  
                                </div>
                                   <div class="col-lg-1 text-center img-cap">
                                    <img src="../assets/images/car-radio.png" alt="">
                                    <p>Music</p>   
                                </div>
                                   <div class="col-lg-1 text-center">
                                    <img src="../assets/images/baggage.png" alt="">
                                    <p class="white-sp">2 Bags</p>
                                </div> 
                            </div>
                        </div> -->
                    </div>
                    
                    <div class="tab-pane fade shadow rounded bg-white" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                        <div class="bg-white">
                            <div id="slide2" class="carousel slide" data-ride="carousel">

                                <!-- Indicators -->
                                <ul class="carousel-indicators">
                                  <li data-target="#slide2" data-slide-to="0" class="active"></li>
                                  <li data-target="#slide2" data-slide-to="1"></li>
                                  <li data-target="#slide2" data-slide-to="2"></li>
                                </ul>
                              
                                <!-- The slideshow -->
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                      <img src="../assets/images/dzire.png" alt="Los Angeles">
                                    </div>
                                    <div class="carousel-item">
                                      <img src="../assets/images/aspire.png" alt="Chicago">
                                    </div>
                                  </div>
                              
                                <!-- Left and right controls -->
                                <a class="carousel-control-prev" href="#slide2" data-slide="prev">
                                  <span class="carousel-control-prev-icon"></span>
                                </a>
                                <a class="carousel-control-next" href="#slide2" data-slide="next">
                                  <span class="carousel-control-next-icon"></span>
                                </a>
                              
                              </div>
                        </div>
                        <!-- <div class="bg-grey pill-base">
                            <p class="text-muted mb-2 image-content">It's a regular air-conditioned sedan gives you comfortable outstation ride experience and is the best economical option for a road trip with your family.</p>
                            <div class="row pill-icon">
                                <div class="col-lg-1 col-sm-6 col-xs-6 text-center">
                                    <img src="../assets/images/air-conditioner.png" alt="">
                                 <p>AC</p>
                                </div>
                                <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                                    <img src="../assets/images/smartphone-charger.png" alt="">
                                   <p>Mobile Charger</p>
                                </div>
                                <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                                    <img src="../assets/images/spray.png" alt="">
                                    <p>Sanitizer</p>   
                                </div>
                                   <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                                    <img src="../assets/images/seat.png" alt="">
                                    <p class="white-sp">4 Seats</p>   
                                </div>
                                   <div class="col-lg-1 text-center img-cap">
                                    <img src="../assets/images/gps.png" alt="">
                                    <p>GPS</p>   
                                </div>
                                   <div class="col-lg-1 text-center img-cap">
                                    <img src="../assets/images/first-aid-kit.png" alt="">
                                    <p>FirstAid Kit</p>  
                                </div>
                                   <div class="col-lg-1 text-center img-cap">
                                    <img src="../assets/images/car-radio.png" alt="">
                                    <p>Music</p>   
                                </div>
                                   <div class="col-lg-1 text-center">
                                    <img src="../assets/images/baggage.png" alt="">
                                    <p class="white-sp">2 Bags</p>
                                </div> 
                            </div>
                        </div> -->
                        
                    </div>
                    
                    <div class="tab-pane fade shadow rounded bg-white" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                        <div class="bg-white"><div id="slide3" class="carousel slide" data-ride="carousel">

                            <!-- Indicators -->
                            <ul class="carousel-indicators">
                              <li data-target="#slide3" data-slide-to="0" class="active"></li>
                              <li data-target="#slide3" data-slide-to="1"></li>
                              <li data-target="#slide3" data-slide-to="2"></li>
                            </ul>
                          
                            <!-- The slideshow -->
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                  <img src="../assets/images/mini.png" alt="Los Angeles">
                                </div>
                                <div class="carousel-item">
                                  <img src="../assets/images/sedan.png" alt="Chicago">
                                </div>
                                <div class="carousel-item">
                                  <img src="../assets/images/suv.png" alt="New York">
                                </div>
                              </div>
                          
                            <!-- Left and right controls -->
                            <a class="carousel-control-prev" href="#slide3" data-slide="prev">
                              <span class="carousel-control-prev-icon"></span>
                            </a>
                            <a class="carousel-control-next" href="#slide3" data-slide="next">
                              <span class="carousel-control-next-icon"></span>
                            </a>
                          
                          </div></div>
                        <!-- <div class="bg-grey pill-base">
                            <p class="text-muted mb-2 image-content">It's a regular air-conditioned SUV gives you comfortable outstation ride experience and is the best economical option for a road trip with your family.</p>
                            <div class="row pill-icon">
                                <div class="col-lg-1 col-sm-6 col-xs-6 text-center">
                                    <img src="../assets/images/air-conditioner.png" alt="">
                                 <p>AC</p>
                                </div>
                                <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                                    <img src="../assets/images/smartphone-charger.png" alt="">
                                   <p>Mobile Charger</p>
                                </div>
                                <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                                    <img src="../assets/images/spray.png" alt="">
                                    <p>Sanitizer</p>   
                                </div>
                                   <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                                    <img src="../assets/images/seat.png" alt="">
                                    <p class="white-sp">4 Seats</p>   
                                </div>
                                   <div class="col-lg-1 text-center img-cap">
                                    <img src="../assets/images/gps.png" alt="">
                                    <p>GPS</p>   
                                </div>
                                   <div class="col-lg-1 text-center img-cap">
                                    <img src="../assets/images/first-aid-kit.png" alt="">
                                    <p>FirstAid Kit</p>  
                                </div>
                                   <div class="col-lg-1 text-center img-cap">
                                    <img src="../assets/images/car-radio.png" alt="">
                                    <p>Music</p>   
                                </div>
                                   <div class="col-lg-1 text-center">
                                    <img src="../assets/images/baggage.png" alt="">
                                    <p class="white-sp">2 Bags</p>
                                </div> 
                            </div>
                        </div> -->
                    </div>
                    
                    <div class="tab-pane fade shadow rounded bg-white" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                        <div class="bg-white"><div id="slide4" class="carousel slide" data-ride="carousel">

                            <!-- Indicators -->
                            <ul class="carousel-indicators">
                              <li data-target="#slide4" data-slide-to="0" class="active"></li>
                              <li data-target="#slide4" data-slide-to="1"></li>
                              <li data-target="#slide4" data-slide-to="2"></li>
                            </ul>
                          
                            <!-- The slideshow -->
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                  <img src="../assets/images/mini.png" alt="Los Angeles">
                                </div>
                                <div class="carousel-item">
                                  <img src="../assets/images/sedan.png" alt="Chicago">
                                </div>
                                <div class="carousel-item">
                                  <img src="../assets/images/suv.png" alt="New York">
                                </div>
                              </div>
                          
                            <!-- Left and right controls -->
                            <a class="carousel-control-prev" href="#slide4" data-slide="prev">
                              <span class="carousel-control-prev-icon"></span>
                            </a>
                            <a class="carousel-control-next" href="#slide4" data-slide="next">
                              <span class="carousel-control-next-icon"></span>
                            </a>
                          
                          </div></div>
                        <!-- <div class="bg-grey pill-base">
                            <p class="text-muted mb-2 image-content">It's a regular air-conditioned SUV gives you comfortable outstation ride experience and is the best economical option for a road trip with your family.</p>
                            <div class="row pill-icon">
                                <div class="col-lg-1 col-sm-6 col-xs-6 text-center">
                                    <img src="../assets/images/air-conditioner.png" alt="">
                                 <p>AC</p>
                                </div>
                                <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                                    <img src="../assets/images/smartphone-charger.png" alt="">
                                   <p>Mobile Charger</p>
                                </div>
                                <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                                    <img src="../assets/images/spray.png" alt="">
                                    <p>Sanitizer</p>   
                                </div>
                                   <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                                    <img src="../assets/images/seat.png" alt="">
                                    <p class="white-sp">4 Seats</p>   
                                </div>
                                   <div class="col-lg-1 text-center img-cap">
                                    <img src="../assets/images/gps.png" alt="">
                                    <p>GPS</p>   
                                </div>
                                   <div class="col-lg-1 text-center img-cap">
                                    <img src="../assets/images/first-aid-kit.png" alt="">
                                    <p>FirstAid Kit</p>  
                                </div>
                                   <div class="col-lg-1 text-center img-cap">
                                    <img src="../assets/images/car-radio.png" alt="">
                                    <p>Music</p>   
                                </div>
                                   <div class="col-lg-1 text-center">
                                    <img src="../assets/images/baggage.png" alt="">
                                    <p class="white-sp">2 Bags</p>
                                </div> 
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
    </div>
   <div class="tab-content">
        <div class="tab-pane fade shadow rounded bg-white col-lg-12" id="show" >
            <div class="bg-grey pill-base">
                <p class="text-muted mb-2 image-content">It's a regular air-conditioned Hatchback gives you comfortable outstation ride experience and is the best economical option for a road trip with your family.</p>
                <div class="row pill-icon">
                    <div class="col-lg-1 col-sm-6 col-xs-6 text-center">
                        <img src="../assets/images/air-conditioner.png" alt="">
                     <p>AC</p>
                    </div>
                    <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                        <img src="../assets/images/smartphone-charger.png" alt="">
                       <p>Mobile Charger</p>
                    </div>
                    <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                        <img src="../assets/images/spray.png" alt="">
                        <p>Sanitizer</p>   
                    </div>
                       <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                        <img src="../assets/images/seat.png" alt="">
                        <p class="white-sp">4 Seats</p>   
                    </div>
                       <div class="col-lg-1 text-center img-cap">
                        <img src="../assets/images/gps.png" alt="">
                        <p>GPS</p>   
                    </div>
                       <div class="col-lg-1 text-center img-cap">
                        <img src="../assets/images/first-aid-kit.png" alt="">
                        <p>FirstAid Kit</p>  
                    </div>
                       <div class="col-lg-1 text-center img-cap">
                        <img src="../assets/images/car-radio.png" alt="">
                        <p>Music</p>   
                    </div>
                       <div class="col-lg-1 text-center">
                        <img src="../assets/images/baggage.png" alt="">
                        <p class="white-sp">2 Bags</p>
                    </div> 
                </div>
            </div>
            
           </div>

<!-- ------------------------------ -->
           <div class="tab-pane fade shadow rounded bg-white col-lg-12" id="show1">
            <div class="bg-grey pill-base">
                <p class="text-muted mb-2 image-content">It's a regular air-conditioned Sedan gives you comfortable outstation ride experience and is the best economical option for a road trip with your family.</p>
                <div class="row pill-icon">
                    <div class="col-lg-1 col-sm-6 col-xs-6 text-center">
                        <img src="../assets/images/air-conditioner.png" alt="">
                     <p>AC</p>
                    </div>
                    <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                        <img src="../assets/images/smartphone-charger.png" alt="">
                       <p>Mobile Charger</p>
                    </div>
                    <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                        <img src="../assets/images/spray.png" alt="">
                        <p>Sanitizer</p>   
                    </div>
                       <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                        <img src="../assets/images/seat.png" alt="">
                        <p class="white-sp">4 Seats</p>   
                    </div>
                       <div class="col-lg-1 text-center img-cap">
                        <img src="../assets/images/gps.png" alt="">
                        <p>GPS</p>   
                    </div>
                       <div class="col-lg-1 text-center img-cap">
                        <img src="../assets/images/first-aid-kit.png" alt="">
                        <p>FirstAid Kit</p>  
                    </div>
                       <div class="col-lg-1 text-center img-cap">
                        <img src="../assets/images/car-radio.png" alt="">
                        <p>Music</p>   
                    </div>
                       <div class="col-lg-1 text-center">
                        <img src="../assets/images/baggage.png" alt="">
                        <p class="white-sp">2 Bags</p>
                    </div> 
                </div>
            </div>
            </div>
<!-- ------------------------------ -->
           <div class="tab-pane fade shadow rounded bg-white col-lg-12" id="show2">
            <div class="bg-grey pill-base">
                <p class="text-muted mb-2 image-content">It's a regular air-conditioned SUV gives you comfortable outstation ride experience and is the best economical option for a road trip with your family.</p>
                <div class="row pill-icon">
                    <div class="col-lg-1 col-sm-6 col-xs-6 text-center">
                        <img src="../assets/images/air-conditioner.png" alt="">
                     <p>AC</p>
                    </div>
                    <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                        <img src="../assets/images/smartphone-charger.png" alt="">
                       <p>Mobile Charger</p>
                    </div>
                    <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                        <img src="../assets/images/spray.png" alt="">
                        <p>Sanitizer</p>   
                    </div>
                       <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                        <img src="../assets/images/seat.png" alt="">
                        <p class="white-sp">4 Seats</p>   
                    </div>
                       <div class="col-lg-1 text-center img-cap">
                        <img src="../assets/images/gps.png" alt="">
                        <p>GPS</p>   
                    </div>
                       <div class="col-lg-1 text-center img-cap">
                        <img src="../assets/images/first-aid-kit.png" alt="">
                        <p>FirstAid Kit</p>  
                    </div>
                       <div class="col-lg-1 text-center img-cap">
                        <img src="../assets/images/car-radio.png" alt="">
                        <p>Music</p>   
                    </div>
                       <div class="col-lg-1 text-center">
                        <img src="../assets/images/baggage.png" alt="">
                        <p class="white-sp">2 Bags</p>
                    </div> 
                </div>
            </div>
          </div>
<!-- ------------------------------ -->
<!-- class="tab-pane fade shadow rounded bg-white" id="v-pills-home-tab"  -->
        <div class="tab-pane fade shadow rounded bg-white col-lg-12 active" id="show3"  role="tabpanel" aria-labelledby="v-pills-settings-tab" aria-selected="true">
            <div class="bg-grey pill-base">
                <p class="text-muted mb-2 image-content">It's a regular air-conditioned XUV gives you comfortable outstation ride experience and is the best economical option for a road trip with your family.</p>
                <div class="row pill-icon">
                    <div class="col-lg-1 col-sm-6 col-xs-6 text-center">
                        <img src="../assets/images/air-conditioner.png" alt="">
                     <p>AC</p>
                    </div>
                    <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                        <img src="../assets/images/smartphone-charger.png" alt="">
                       <p>Mobile Charger</p>
                    </div>
                    <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                        <img src="../assets/images/spray.png" alt="">
                        <p>Sanitizer</p>   
                    </div>
                       <div class="col-lg-1 col-sm-6 col-xs-6  text-center img-cap">
                        <img src="../assets/images/seat.png" alt="">
                        <p class="white-sp">4 Seats</p>   
                    </div>
                       <div class="col-lg-1 text-center img-cap">
                        <img src="../assets/images/gps.png" alt="">
                        <p>GPS</p>   
                    </div>
                       <div class="col-lg-1 text-center img-cap">
                        <img src="../assets/images/first-aid-kit.png" alt="">
                        <p>FirstAid Kit</p>  
                    </div>
                       <div class="col-lg-1 text-center img-cap">
                        <img src="../assets/images/car-radio.png" alt="">
                        <p>Music</p>   
                    </div>
                       <div class="col-lg-1 text-center">
                        <img src="../assets/images/baggage.png" alt="">
                        <p class="white-sp">2 Bags</p>
                    </div> 
                </div>
            </div>
        </div>
   </div>
    <!-- <div class="row services-row">
            <div class="col-lg-4 col-md-4">
                <img class="img-responsive" src="../assets/images/service-tax.jpg" alt="">
            </div>
            <div class="col-lg-8 col-md-8 taxi-text">
            <h2>Services we provide</h2>
            <ul>
                    <li><i class="fa fa-share" aria-hidden="true"></i>&nbsp;&nbsp;We provide 24x7 Customer Care Service.</li>
                    <li><i class="fa fa-share" aria-hidden="true"></i>&nbsp;&nbsp;We do provide in time service to you.</li>
                    <li><i class="fa fa-share" aria-hidden="true"></i>&nbsp;&nbsp;We Maintain Good Condition Vehicles.</li>
                    <li><i class="fa fa-share" aria-hidden="true"></i>&nbsp;&nbsp;We Maintain Experienced and managed Drivers with us.</li>
                    <li><i class="fa fa-share" aria-hidden="true"></i>&nbsp;&nbsp;We Provide Affoordable Pricesto our Customers.</li>
                </ul>
            </div>
        </div>
        <br/> -->
</div>
</section>



<!-- -------------------header------------------ -->


