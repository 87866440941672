import { Component, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-car-rental-packages-with-driver-and-fuel',
  templateUrl: './car-rental-packages-with-driver-and-fuel.component.html',
  styleUrls: ['./car-rental-packages-with-driver-and-fuel.component.css']
})
export class CarRentalPackagesWithDriverAndFuelComponent implements OnInit {
  title = 'Best service low price car rental in Hyderabad  | Best Car rental Hyderabad | book cabs online prices | yodrives.com';
  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'keywords', content: 'car booking tariff in hyderabad,Car rental in Hyderabad, Sanitized car rental in Hyderabad, hourly cab rentals Hyderabad,  rent a car with driver Hyderabad, Disinfected car rental in Hyderabad,Hyderabad Cabs , Car Hire , Taxi India , book cabs , Cab online , Taxi Cabs , Call Cab , Taxi on Call , Airport Transfer' },
      { name: 'author', content: 'yodrives - Bhukya Tours and Travels' },
      { name: 'keyphrase', content: 'car booking tariff in hyderabad,Car rental in Hyderabad, Sanitized car rental in Hyderabad, hourly cab rentals Hyderabad,  rent a car with driver Hyderabad, Disinfected car rental in Hyderabad,Hyderabad Cabs , Car Hire , Taxi India , book cabs , Cab online , Taxi Cabs , Call Cab , Taxi on Call , Airport Transfer' },
      { name: 'description', content: 'Sanitized and disinfected car rental in Hyderabad. Hire a local hourly cab or book outstation taxi service in Hyderabad at an affordable price with YoDrives' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);
  }

}
