<section id="payment-component">
    <div class="main-head">
        <p>Payment Modes</p>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <h3>ALL IN ONE  QR Codes</h3>
                <p>We are facilitating different payment options for the convenience of our customers, you can do payment just by swiping the phone using following different payment methods.</p>
            </div>
            <div class="style_prevu_kit col-lg-4 mode">
                <h5 class="text-center">Gpay</h5>
               <img src="../assets/images/gpayy.png" alt="">
               <p class="p-link"><a href="https://gpay.app.goo.gl/Hpa5tn" target="_blank">https://gpay.app.goo.gl/Hpa5tn</a></p>
            </div>
              <div class="style_prevu_kit col-lg-4 mode">
                  <h5 class="text-center">Paytm</h5>
                <img src="../assets/images/paytmm.png" alt=""></div>
              <div class="style_prevu_kit col-lg-4 mode">
                  <h5 class="text-center">PhonePe</h5>
                <img src="../assets/images/Phonepe.png" alt="" style="border-radius: 5px;">
                <p class="p-link"><a href="https://phon.pe/rgqvy7o7" target="_blank">https://phon.pe/rgqvy7o7</a></p></div>
           </div>
           <div class="row details">
            <div class="col-lg-3">
                <h5>Bank Details</h5>
                <p>BHUKYA TOURS AND TRAVELS <br>
                 A/c No - 39092202218 <br>
                 IFSC - SBIN0010098 <br>
                 Kukatpally/KPHB Branch<br>
                 <b>GST</b> - 36AIBPB5569L1Z2 <br>
                 
             </p>
            </div>
            <div class="col-lg-3">
             <h5>UPI Code</h5>
             <p>8885255144@okbizaxis<br>
             8885255144@paytm</p>
            </div>
            <div class="col-lg-3">
                <h5>International Payment</h5>
                <p>VPA/UPI ID bhavsingh@unionbank <br>
                    (<a href="https://www.remitly.com/us/en/india" target="_blank">Remitly</a> - US/Canada/etc)</p>
            </div>
            <div class="col-lg-3">
                <h5>Payment Gateway</h5>
                <p> <a href="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?saralID=-918800095" target="_blank">SBI</a></p>
            </div>
           </div>
    </div>
</section>
