import { Component, OnInit } from '@angular/core';
import {  FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CommonService } from '../shared/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;
  submitted = false;
  public otp: boolean = false;
  timeLeft: number;
  public verifyBtn: boolean = true;
  public isValid: boolean = true;
  public resendOtp = false;
  public mobile: boolean = false;
  public showTime:boolean;
  public verifyOtp:boolean=true;
  public sendMessage:boolean=false;
  public verifyBtnDisable:boolean=true;
  public digitsVal:any;
  public mobVer:boolean=false;


  constructor(private formBuilder: FormBuilder, private commonService: CommonService,private SpinnerService: NgxSpinnerService,private router: Router,private toastr: ToastrService) { }
  registerForm = new FormGroup({
    mobnumber: new FormControl()
  })
  ngOnInit(): void {


    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      companyName:['',Validators.required],
      message:['',Validators.required],
      mobileNo: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      otp: ['', Validators.required]
    });
  }

  get f() { return this.contactForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.SpinnerService.show();

    let phoneno = /^\d{10}$/;
    let emailValid="^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"

    if(!this.contactForm.value.mobileNo.match(phoneno)) {
      this.SpinnerService.hide();
      return;
    }

    if(!this.contactForm.value.email.match(emailValid)) {
      this.SpinnerService.hide();
      return;
    }

    if (this.contactForm.value.mobileNo != null && this.contactForm.value.mobileNo != '' && this.contactForm.value.mobileNo != undefined && this.contactForm.value.otp == "" || this.contactForm.value.otp == undefined) {

      this.SpinnerService.show();
      this.showTime=true;
      this.timeLeft = 120;
      let interval;

      interval = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
          if (this.timeLeft == 0) {
            this.resendOtp = true;
            this.verifyOtp=false;
            this.showTime=false;
            this.otp=false;
          }
        } else {
          this.timeLeft = 0;
        }
      }, 1000);

      this.commonService.sendVerificationCodeToMobile(this.contactForm.value.mobileNo).subscribe(response => {
        if (response != null) {
          this.verifyBtn = false;
          this.mobile = true;
          this.SpinnerService.hide();
          this.otp = true;
          this.toastr.success('Verification code has been sent to registered mobile.', 'Contact.');
        } else {
          this.verifyBtn = true;
          this.otp = false;
          this.SpinnerService.hide();
        }
      });


    }else if (this.contactForm.value.otp != "" && this.contactForm.value.otp != undefined && this.contactForm.value.otp != null && this.isValid) {
      this.SpinnerService.show();
      this.commonService.verifyVerificationCodeToMobile(this.contactForm.value.mobileNo, this.contactForm.value.otp).subscribe(response => {
        if (response.successCode == 100) {
          this.SpinnerService.hide();
          this.showTime=false;
          this.otp = false;
          this.resendOtp=false;
          this.verifyOtp=false;
          this.sendMessage=true;
          this.toastr.success('OTP verified successfully.', 'Contact.');
          this.isValid = false;
        } else {
          this.toastr.error('Please enter correct OTP.', 'Contact.');
          this.verifyOtp=true;
          this.otp = true;
          this.SpinnerService.hide();
        }
      });
    }else {
    this.commonService.sendContactMail(this.contactForm.value.mobileNo, this.contactForm.value.name,this.contactForm.value.email,this.contactForm.value.companyName,this.contactForm.value.message).subscribe(response => {
      this.SpinnerService.show();
      if (response.successCode == 100) {
        this.SpinnerService.hide();
        this.toastr.success('Mail sent successfully.', 'Contact.');
        this.router.navigate(['/confirm']);
        this.contactForm.reset();
      } else {
        this.SpinnerService.hide();
        this.toastr.error('Mail not sent .', 'Contact');
      }
    });
  }

  }

  mobWhatsapp(){
    this.registerForm.valueChanges.subscribe(
      digits =>
      this.digitsVal=digits
    );
  }
  onSelect() {
    this.SpinnerService.show();
    let interval;
    this.timeLeft = 120;
    this.otp = true;
    this.showTime=true;
    this.resendOtp=false;
    this.verifyOtp=false;
    interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        if (this.timeLeft == 0) {
          this.resendOtp = true;
          this.otp = false;
          this.verifyOtp=false;
          this.showTime=false;
        }
      } else {
        this.timeLeft = 0;
      }
    }, 1000);
    this.commonService.sendVerificationCodeToMobile(this.contactForm.value.mobileNo).subscribe(response => {
      if (response != null) {
        this.SpinnerService.hide();
        this.verifyBtn = false;
        this.verifyOtp=true;
        this.otp = true;
        this.toastr.success('Verification code has been sent to registered mobile.', 'Contact');

      } else {
        this.verifyBtn = true;
        this.otp = false;
      }
    });
  }



}
