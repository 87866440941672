<section id="package-component">
    <div class="main-head">
        <p>Car Rental Packages With Driver and Fuel</p>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 note">
                <br><h3>Car Rental Packages With Driver and Fuel</h3>
                <div class="table-responsive">
                  <table class="table table-bordered w-100 table-responsive" style="margin-bottom: 0px;">
                    <thead class="thead-yellow">
                      <tr>
                        <th scope="col">Package Type</th>
                        <th scope="col">Duration/Kms</th>
                        <!-- <th scope="col"><span>Mini</span> (Indica / A-Star)</th> -->
                        <th scope="col"><span>Sedan</span><br> (Dzire / Ford)</th>
                        <th scope="col"><span>SUV</span><br> (Ertiga)</th>
                        <th scope="col"><span>SUV</span><br> (Innova/Marazzo)</th>
                        <th scope="col"><span>SUV +</span><br> (Crysta)</th>
                        <th scope="col"><span>Fortuner</span></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="grey-clr">
                        <th scope="row" rowspan="4"><p>Local / Hourly Packages </p><p> Exclusive Service</p>  </th>
                        <!-- <td>2 Hours 20 Kms</td>
                        <td>₹350</td>
                        <td>₹450</td>
                        <td>₹700</td>
                        <td>₹900</td> -->
                      </tr>   
                      <tr class="grey-clr">
                        <!-- <td>4 Hours 40 Kms</td>
                        <td>₹700</td>
                        <td>₹900</td>
                        <td>₹1250</td>
                        <td>₹1800</td> -->
                      </tr>
                      <tr class="grey-clr">
                        <td>8 Hours 80 Kms</td>
                        <!-- <td>₹1400</td> -->
                        <td>₹2400</td>
                        <td>₹3200</td>
                        <td>₹3400</td>
                        <td>₹4000</td>
                        <td>₹7500</td>
                      </tr>
                      <tr class="grey-clr">
                        <td>12 Hours 120 Kms</td>
                        <!-- <td>₹2100</td> -->
                        <td>₹3400</td>
                        <td>₹4300</td>
                        <td>₹4800</td>
                        <td>₹6000</td>
                        <td>₹1100</td>
                      </tr>
                      <!-- <tr class="green-clr">
                        <th scope="row">City To Airport</th>
                        <td>40 Kms</td>
                        <td>₹700</td>
                        <td>₹900</td>
                        <td>₹900</td>
                        <td>₹1250</td>
                        <td>₹1800</td>
                      </tr> -->
                      <!-- <tr class="green-clr">
                        <th scope="row">Airport To City</th>
                        <td>40 Kms</td>
                       <td>₹600</td>
                        <td>₹800</td>
                        <td>₹900</td>
                        <td>₹1100</td>
                        <td>₹1600</td>
                      </tr> -->
                      <tr class="green-clr">
                        <th scope="row">Airport pickup/drop</th>
                        <td>80 Kms</td>
                        <!-- <td>₹1200</td> -->
                        <td>₹2200</td>
                        <td>₹3000</td>
                        <td>₹3200</td>
                        <td>₹3600</td>
                        <td>₹6500</td>
                      </tr>
                      <tr class="blue-clr">
                        <th scope="row">Outstation</th>
                        <td>300 kms</td>
                        <!-- <td>₹3000</td> -->
                        <td>₹3900</td>
                        <td>₹4800</td>
                        <td>₹5400</td>
                        <td>₹6000</td>
                        <td>₹14400</td>
                      </tr>
                      <tr class="orange-clr">
                        <th scope="row">Extra Kms</th>
                        <td></td>
                        <!-- <td>₹10/km</td> -->
                        <td>₹13/km</td>
                        <td>₹16/km</td>
                        <td>₹18/km</td>
                        <td>₹22/km</td>
                        <td>₹48/km</td>
                      </tr>
                      <tr class="orange-clr">
                        <th scope="row">Extra Hour</th>
                        <td></td>
                        <!-- <td>₹100/Hr</td> -->
                        <td>₹150/Hr</td>
                        <td>₹200/Hr</td>
                        <td>₹200/Hr</td>
                        <td>₹300/Hr</td>
                        <td>₹500/Hr</td>
                      </tr>
                      <tr class="orange-clr">
                        <th scope="row">Driver Bata</th>
                        <td></td>
                        <!-- <td>₹100/Hr</td> -->
                        <td>₹400</td>
                        <td>₹500</td>
                        <td>₹500</td>
                        <td>₹600</td>
                        <td>₹600</td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- <i style="font-size:0.7rem">Note: Above price is updated on 01-May-2022 at diesel price ₹105</i> -->
                </div>
                <br>
                <!-- Tempo/Bus rental packages with driver and fuel -->
                <br><h3>Tempo/Bus rental packages with driver and fuel</h3>
                <div class="table-responsive">
                  <table class="table table-bordered w-100 table-responsive" style="margin-bottom: 0px;">
                    <thead class="thead-yellow">
                      <tr>
                        <th scope="col">Package Type</th>
                        <th scope="col">Duration/Kms</th>
                        <th scope="col"><span>Tempo<br>Traveler</span><br>12 Seater</th>
                        <th scope="col"><span>Urbania<br>Luxury</span><br>12 Seater</th>
                        <th scope="col"><span>Tempo<br>Traveler</span><br>16 Seater</th>
                        <th scope="col"><span>BUS</span><br>22 Seater</th>
                        <th scope="col"><span>BUS</span><br>28/30 Seater</th>
                        <th scope="col"><span>BUS</span><br>40 Seater</th>
                        <th scope="col"><span>BUS</span><br>50 Seater</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="grey-clr">
                        <th scope="row"><p>Local / Hourly Packages </p> </th>
                        <td>8 Hours 80 Kms</td>
                        <td>AC-₹6000<br>NAC-₹5000</td>
                        <td>AC-₹9000</td>
                        <td>₹8000<br>₹7000</td>
                        <td>₹10000<br>₹8000</td>
                        <td>₹11000<br>₹9000</td>
                        <td>₹12000<br>₹10000</td>
                        <td>NAC-₹12000</td>
                      </tr>
                      <tr class="blue-clr">
                        <th scope="row">Outstation</th>
                        <td>300 kms</td>
                        <td>AC-₹8100<br>NAC-₹7500</td>
                        <td>₹12000<br>₹11400</td>
                        <td>₹10200<br>₹9600</td>
                        <td>₹12000<br>₹11400</td>
                        <td>₹15000<br>₹13500</td>
                        <td>₹18600<br>₹17100</td>
                        <td>NAC-₹18000</td>
                      </tr>
                      <tr class="orange-clr">
                        <th scope="row">Extra Kms</th>
                        <td></td>
                        <td>AC-₹27/km<br>NAC-₹25/km</td>
                        <td>₹40/km<br>₹38/km</td>
                        <td>₹34/km<br>₹32/km</td>
                        <td>₹40/km<br>₹38/km</td>
                        <td>₹50/km<br>₹45/km</td>
                        <td>₹62/km<br>₹57/km</td>
                        <td>NAC-₹60/km</td>
                      </tr>
                      <tr class="orange-clr">
                        <th scope="row">Extra Hour</th>
                        <td></td>
                        <!-- <td>₹100/Hr</td> -->
                        <td>₹500/Hr</td>
                        <td>₹600/Hr</td>
                        <td>₹500/Hr</td>
                        <td>₹500/Hr</td>
                        <td>₹500/Hr</td>
                        <td>₹600/Hr</td>
                        <td>₹600/Hr</td>
                      </tr>
                      <tr class="orange-clr">
                        <th scope="row">Driver Bata</th>
                        <td></td>
                        <td>₹1000</td>
                        <td>₹1000</td>
                        <td>₹1000</td>
                        <td>₹1000</td>
                        <td>₹1000</td>
                        <td>₹1000</td>
                        <td>₹1000</td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- <i style="font-size:0.7rem">Note: Above price is updated on 01-May-2022 at diesel price ₹105</i> -->
                </div>
                <br>
                <h5><i><b>Note :</b></i></h5>
                <ol>
                  <li><i>Above prices may vary based on car model, year, KMs run, season,etc.</i></li>
                  <li><i>For corporate booking, bulk booking and long term booking discounts contact sales team.</i></li>
                </ol>
                <br>
                <div>
                  <h4>Terms and Conditions</h4>
                  <ol>
                      <li>One day means one calendar day (12 midnight to 12 midnight).</li>
                      <li>Kilometers and Hours are calculated from garage to garage.</li>
                      <li>Toll, Parking, Inter State Permit etc. not included in the above cost, customer have to pay extra as applicable.</li>
                      <li>Both extra hours and KMs are applicable.</li>
                      <li>Driver batta Rs 400 to 1000 applicable as per the package and vehicle type.</li>
                      <li>Extra driver allowance if cab used before 6:00AM and after 10:00PM.</li>
                      <li>Customer need to take care of belongings before leaving the cab.</li>
                      <li>There may be delays due to bandh, traffic jam,rains,etc.</li>
                      <li>As per Govt rules cabs are speed controlled to 80 km/hour.</li>
                      <li>Booking should be confirmed before 12 hours with Rs 1000+Tax payment.</li>
                      <li>Cancellation should be informed 6 hours prior the trip start otherwise cancellation charges of Rs 1000+Tax applicable.</li>
                      <li>Alcoholic beverages and smoking are strictly prohibited. Be respectful of your driver's wishes as it is their office you're sitting in.</li>
                      <li>Customer is responsible and liable for carrying unauthorized items like liquor bottles, ganza,etc.</li>
                  </ol>
                </div>
            </div>
        </div>
    </div>
</section>