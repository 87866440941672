<section id="booking-content">
    <div class="container">
        <div class="row body-pad-btm">
            <div class="col-lg-6 panel-border">
                <div class="booking-form">
                    <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
                        <p class="panel-head">Driver Registration.</p>
                        <div class="form-group">
                          <input type="text" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }"  class="form-control form-control-sm"  aria-describedby="emailHelp" maxlength="50" placeholder="Enter Driver Name">
    
                          <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Driver Name is required</div>
                        </div>
                        </div>

                        <div class="form-group">
                            <div class="input-group mb-2">
                                <div class="input-group-prepend">
                                  <div class="input-group-text form-control-sm">+91 India</div>
                                </div>
                                <input type="text" [attr.disabled]="mobile ? '' : null" formControlName="mobileNo" [ngClass]="{ 'is-invalid': submitted && f.mobileNo.errors }"  class="form-control form-control-sm" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10" placeholder="Enter Mobile No">
                                <div *ngIf="submitted && f.mobileNo.errors" class="invalid-feedback">
                                    <div *ngIf="f.mobileNo.errors.required">Mobile no is required</div>
                                    <div *ngIf="f.mobileNo.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
                                </div> 
                            </div>
                        </div>
                        <div class="form-group form-group-pad-btm">
                            <button class="otp-btn" type="submit" *ngIf="verifyBtn">Generate OTP</button>
                        </div>
                         <div *ngIf="otp" class="form-group">
                            <input type="otp" formControlName="otp" class="form-control form-control-sm" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"   maxlength="6" aria-describedby="emailHelp" placeholder="Enter OTP">
    
                            <!-- <div *ngIf="submitted" class="invalid-feedback">
                                <div>OTP verified successfully.</div>
                            </div> -->
    
                            <small *ngIf="showTime" class="verify-code">We have sent the verification code {{timeLeft}} Seconds Left....</small> 
                            <br>
                            <button *ngIf="verifyOtp" class="otp-btn" type="submit">Verify OTP</button>
                        </div>
                        <p *ngIf="resendOtp">Not received your code? <a class="w3-button w3-black" (click)="onSelect()" >Resend Code</a></p>
    
                        <div class="form-group">
                            <button type="submit" [disabled]="isValid" class=" panel-butn pull-right">Submit</button>
                        </div>
                    </form>
                </div>
            </div> 
            <div class="col-lg-6 panel-border">
                    <h3 class="document">Should have following documents</h3>
                    <ol>
                      <li>Original Driving License (Should have 3 years experienced license)</li>
                      <li>Aadhaar Card & Pan card</li>
                      <li>Bank Passbook</li>
                      <li>Passport Size Photo laste two copies</li>
                      <li>Vaccination Certificate</li>
                      <li>PF and ESI cards if available</li>
                      <li>Police verification certificate (PVC), BGV, DDT, Medical</li>
                      <li>Take one pair Driver Dress and shoes</li>
                      <li>Past experience References <br> i. Experience 1 - Contact<br> ii. Experience 2 - Contact</li>
                      <li>Reference Number<br>
                          i. Non Family (Friend,etc)<br>
                          ii. Family (Father, Brother, Mother or Sister)
                      </li>
                    </ol>
            </div>
        </div><br><br>
        <!-- <div>
            <h3 class="document">Should have following documents</h3>
                  <ol>
                    <li>Original Driving License (Should have 3 years experienced license)</li>
                    <li>Aadhaar Card & Pan card</li>
                    <li>Bank Passbook</li>
                    <li>Passport Size Photo laste two copies</li>
                    <li>Vaccination Certificate</li>
                    <li>PF and ESI cards if available</li>
                    <li>Police verification certificate (PVC), BGV, DDT, Medical</li>
                    <li>Take one pair Driver Dress and shoes</li>
                    <li>Past experience References <br> i. Experience 1 - Contact<br> ii. Experience 2 - Contact</li>
                    <li>Reference Number<br>
                        i. Non Family (Friend,etc)<br>
                        ii. Family (Father, Brother, Mother or Sister)
                    </li>
                  </ol>
        </div> -->
    </div>
</section>