<section id="booking-content">
    <div class="container">
        <div class="row body-pad-btm">
            <div class="col-lg-6 panel-border">
                <div class="booking-form">
                    <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
                        <p class="panel-head">Hire Taxi Driver.</p>
                        <div class="form-group">
                          <input type="text" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }"  class="form-control form-control-sm"  aria-describedby="emailHelp" maxlength="50" placeholder="Enter Customer Name">
    
                          <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Customer Name is required</div>
                        </div>
                        </div>

                        <div class="form-group">
                            <input type="text" formControlName="address" [ngClass]="{ 'is-invalid': submitted && f.address.errors }"  class="form-control form-control-sm"  aria-describedby="emailHelp" maxlength="150" placeholder="Enter Address">
      
                            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                              <div *ngIf="f.address.errors.required">Address is required</div>
                          </div>
                          </div>

                          <div class="form-group">
                            <select id="inputState" formControlName="purpose" [ngClass]="{ 'is-invalid': submitted && f.purpose.errors }" class="form-control">
                                <option value="">Purpose</option>
                                <option value="Company">Company</option>
                                <option value="Personal">Personal</option>
                                <option value="Other">Other</option>
                              </select>
                              <div *ngIf="submitted && f.purpose.errors" class="invalid-feedback">
                                <div *ngIf="f.purpose.errors.required">Please select Purpose</div>
                            </div>

                          </div>

                          <div class="form-group locate">
                            <input type="text" formControlName="date"  [ngClass]="{ 'is-invalid': submitted && f.date.errors }" placeholder="Date and Time" class="form-control locate-text calender" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" [min]="min" >
                           
                            <owl-date-time #dt1></owl-date-time>

                            <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
                              <div *ngIf="f.date.errors.required">Date and Time is required</div>
                          </div>
                        </div>

                        <div class="form-group">
                            <input type="text" formControlName="noOfDays" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  [ngClass]="{ 'is-invalid': submitted && f.noOfDays.errors }"  class="form-control form-control-sm"  aria-describedby="emailHelp" maxlength="4" placeholder="Enter Number Of Days">
      
                            <div *ngIf="submitted && f.noOfDays.errors" class="invalid-feedback">
                              <div *ngIf="f.noOfDays.errors.required">Number Of Days is required</div>
                          </div>
                          </div>


                        <div class="form-group">
                            <div class="input-group mb-2">
                                <div class="input-group-prepend">
                                  <div class="input-group-text form-control-sm">+91 India</div>
                                </div>
                                <input type="text" [attr.disabled]="mobile ? '' : null" formControlName="mobileNo" [ngClass]="{ 'is-invalid': submitted && f.mobileNo.errors }"  class="form-control form-control-sm" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10" placeholder="Enter Mobile No">
                                <div *ngIf="submitted && f.mobileNo.errors" class="invalid-feedback">
                                    <div *ngIf="f.mobileNo.errors.required">Mobile no is required</div>
                                    <div *ngIf="f.mobileNo.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
                                </div> 
                            </div>
                        </div>
                        <div class="form-group form-group-pad-btm">
                            <button class="otp-btn" type="submit" *ngIf="verifyBtn">Generate OTP</button>
                        </div>
                         <div *ngIf="otp" class="form-group">
                            <input type="otp" formControlName="otp" class="form-control form-control-sm" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"   maxlength="6" aria-describedby="emailHelp" placeholder="Enter OTP">
    
                            <!-- <div *ngIf="submitted" class="invalid-feedback">
                                <div>OTP verified successfully.</div>
                            </div> -->
    
                            <small *ngIf="showTime" class="verify-code">We have sent the verification code {{timeLeft}} Seconds Left....</small> 
                            <br>
                            <button *ngIf="verifyOtp" class="otp-btn" type="submit">Verify OTP</button>
                        </div>
                        <p *ngIf="resendOtp">Not received your code? <a class="w3-button w3-black" (click)="onSelect()" >Resend Code</a></p>
    
                        <div class="form-group">
                            <button type="submit" [disabled]="isValid" class=" panel-butn pull-right">Submit</button>
                        </div>
                    </form>
                </div>
            </div> 
        </div>
    </div>
</section>