
import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import {CommonService} from '../shared/common.service'
import * as $ from 'jquery';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AgmCoreModule, MapsAPILoader } from "@agm/core";
import { DatePipe } from '@angular/common';
declare var $: any;




@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  public dateTime: Date;  
  public min = new Date();
  vehicleTypesData: any = [];
  noOfPassengersData: any = [];
  latitude: number;
  longitude: number;
  droplatitude: number;
  droplongitude: number;
  private geoCoder;
  public pickUpPlace:any;
  public droppingPlace:any;
  public splitPickupPlace:any;
  public splitDropPlace:any;
  selectedProp: string='';
  public setDob:string;
  public differentOrigin:boolean=false;


  @ViewChild('search', { static: false })
  public searchElementRef: ElementRef;


  @ViewChild('searchPlaces', { static: false })
  public searchElementRef1: ElementRef;
  registerForm: FormGroup;
  submitted = false;


  constructor(private commonService: CommonService,private mapsAPILoader: MapsAPILoader,private ngZone: NgZone,private formBuilder: FormBuilder,private router: Router) { }

  ngOnInit(): void {

      this.registerForm = this.formBuilder.group({
          firstName: ['', Validators.required],
          droppingPlace:['',Validators.required],
          date:['',Validators.required],
          vehicleType:['',Validators.required]
          // noOfPersons:['',Validators.required]
      });
  

    this.getAllVehicleTypes();
    this.getAllSeatingCapacity();
    
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef1.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          this.pickUpPlace=place.formatted_address;
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
           this.latitude = place.geometry.location.lat();
           this.longitude = place.geometry.location.lng();
        });
      });
    });


    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          this.droppingPlace=place.formatted_address;



          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.droplatitude = place.geometry.location.lat();
           this.droplongitude = place.geometry.location.lng();
        });
      });
    });
  }


  get f() { return this.registerForm.controls; }


  getAllVehicleTypes() {
    this.commonService.getAllVehicleTypes().subscribe(response => {
      if (response.status.success && response.tblvehicletypeVOs!=null) {
        this.vehicleTypesData = response.tblvehicletypeVOs;
      } else {
        this.vehicleTypesData = [];
      }
    });
  }

  getAllSeatingCapacity() {
    this.commonService.getAllVehicleTypes().subscribe(response => {
      if (response.status.success && response.tblvehicletypeVOs!=null) {
        this.noOfPassengersData=response.tblvehicletypeVOs;
        
        this.noOfPassengersData.forEach((item, index) => {
          if (index !== this.noOfPassengersData.findIndex(i => i.iSeatingCapacity === item.iSeatingCapacity)) {
            this.noOfPassengersData.splice(index, 1);
          }
          
      });
      
      } else {
        this.vehicleTypesData = [];
      }
    });
  }


  onSubmit() {
    
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    
    let pick=this.pickUpPlace;
    
    let drop=this.droppingPlace;
    //this.splitPickupPlace =pick.split(',')[0]
    //this.splitDropPlace = drop.split(',')[0];
    this.splitPickupPlace =pick.substring(0,27);
    this.splitDropPlace = drop.substring(0,27);
    if(this.splitPickupPlace.length<=0){
      this.splitPickupPlace = 'Hyderabad';
    }
    if(this.splitDropPlace.length<=0){
      this.splitPickupPlace = 'Hyderabad';
    }
    
    if(this.splitPickupPlace==this.splitDropPlace){
      this.differentOrigin=true;
      return;
    }

    const nyc = new google.maps.LatLng(this.latitude, this.longitude);
    const london = new google.maps.LatLng(this.droplatitude,this.droplongitude);
    const distance = google.maps.geometry.spherical.computeDistanceBetween(london,nyc) / 1000;
    console.log(distance);


    //console.log(vm.splitPickupPlace);
    //vm.pickUpDateTime.date.replace('GMT+0530 (IST)','');
  if(this.submitted){
    this.differentOrigin=false;

    var datePipe = new DatePipe('en-US');

    this.setDob = datePipe.transform(this.registerForm.value.date, 'dd/MM/yyyy h:mm a');

        localStorage.setItem('splitPickupPlace', this.splitPickupPlace);
        localStorage.setItem('splitDropPlace', this.splitDropPlace);
        localStorage.setItem('date', this.setDob);
        localStorage.setItem('vehicleType', this.registerForm.value.vehicleType);
        //localStorage.setItem('noOfPersons', this.registerForm.value.noOfPersons);
        if(localStorage.vehicleType == "5 Seater"){
          localStorage.setItem('noOfPersons', "5");
        }else if(localStorage.vehicleType == "6 Seater"){
          localStorage.setItem('noOfPersons', "6");
        }else if(localStorage.vehicleType == "7 Seater"){
          localStorage.setItem('noOfPersons', "7");
        }else if(localStorage.vehicleType == "8 Seater"){
          localStorage.setItem('noOfPersons', "8");
        }else{
          localStorage.setItem('noOfPersons', "3");
        }
        
       
        this.router.navigate(['/booking']);
       }
   // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value))
}


deg2rad(deg) {
  return deg * (Math.PI / 180)
}

}
// for scrolling client images starts
   
var $clientcarousel = $('#clients-list');
var clients = $clientcarousel.children().length;
var clientwidth = (clients * 220); // 140px width for each client item 
$clientcarousel.css('width',clientwidth);

var rotating = true;
var clientspeed = 0;
var seeclients = setInterval(rotateClients, clientspeed);

$(document).on({
  mouseenter: function(){
    rotating = false; // turn off rotation when hovering
  },
  mouseleave: function(){
    rotating = true;
  }
}, '#clients');

function rotateClients() {
  if(rotating != false) {
    var $first = $('#clients-list li:first');
    $first.animate({ 'margin-left': '-220px' }, 2000, "linear", function() {
      $first.remove().css({ 'margin-left': '0px' });
      $('#clients-list li:last').after($first);
    });
  }
}
// for scrolling client images ends

// for scrolling client images starts
   
var $clientcarousel = $('#partners-list');
var clients = $clientcarousel.children().length;
var clientwidth = (clients * 220); // 140px width for each client item 
$clientcarousel.css('width',clientwidth);

var rotating = true;
var clientspeed = 0;
var seeclients = setInterval(rotatePartners, clientspeed);

$(document).on({
  mouseenter: function(){
    rotating = false; // turn off rotation when hovering
  },
  mouseleave: function(){
    rotating = true;
  }
}, '#partners');

function rotatePartners() {
  if(rotating != false) {
    var $first = $('#partners-list li:first');
    $first.animate({ 'margin-left': '-220px' }, 2000, "linear", function() {
      $first.remove().css({ 'margin-left': '0px' });
      $('#partners-list li:last').after($first);
    });
  }
}
// for scrolling client images ends

