<section id="contact-content">
    <div class="main-head">
        <p>Contact Us</p>
    </div>
    <div class="container">
        <div class="container form-container">
            <div class="row">
                <div class="col-lg-8 sendUsMsgForm box-shadow wow fadeInUp" data-wow-delay="0.9s">
                    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" novalidate>
                        <h4>Write To Us</h4>
                        <div class="form-row">
                            <div class="col-md-6 form-group float-label-control ">

                                <input type="text" class="form-control" formControlName="name"
                                    [ngClass]="{ 'is-invalid': submitted && f.name.errors }" placeholder="Name"
                                    aria-describedby="emailHelp" maxlength="50" required>
                                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                    <div *ngIf="f.name.errors.required">Name is required</div>
                                </div>
                            </div>
                            <div class="col-md-6 form-group float-label-control">
                                <input type="email" class="form-control" formControlName="email"
                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email"
                                    id="inputEmail4" maxlength="50" required>
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Email id required</div>
                                    <div *ngIf="f.email.errors.pattern">Please provide a valid email address</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-md-6 form-group float-label-control">
                                <input type="text" formControlName="mobileNo"
                                    [ngClass]="{ 'is-invalid': submitted && f.mobileNo.errors }" class="form-control"
                                    placeholder="Mobile Number" name="mobile"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    maxlength="10" required>

                                <div *ngIf="submitted && f.mobileNo.errors" class="invalid-feedback">
                                    <div *ngIf="f.mobileNo.errors.required">Mobile no is required</div>
                                    <div *ngIf="f.mobileNo.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
                                </div>

                            </div>
                            <div class="col-md-6 form-group float-label-control">
                                <input type="text" class="form-control" formControlName="companyName"
                                    [ngClass]="{ 'is-invalid': submitted && f.companyName.errors }"
                                    placeholder="Subject - I want Car/Tempo/&#10;SUV or I Suggest You" maxlength="50"
                                    required>
                                <div *ngIf="submitted && f.companyName.errors" class="invalid-feedback">
                                    <div *ngIf="f.companyName.errors.required">Subject is required</div>
                                </div>

                            </div>
                        </div>
                        <div class="form-group float-label-control">
                            <textarea formControlName="message"
                                [ngClass]="{ 'is-invalid': submitted && f.message.errors }" cols="30" rows="1"
                                maxlength="1500" class="form-control" placeholder="Enuiry/Comments/Suggestions"
                                required></textarea>

                            <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                <div *ngIf="f.message.errors.required">Comments is required</div>
                            </div>
                        </div>

                        <div *ngIf="otp" class="form-group">
                            <input type="otp" formControlName="otp" class="form-control form-control-sm"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                maxlength="6" aria-describedby="emailHelp" placeholder="Enter OTP">

                            <small *ngIf="showTime" class="verify-code">We have sent the verification code {{timeLeft}}
                                Seconds Left....</small>
                        </div>

                        <p *ngIf="resendOtp">Not received your code? <a class="w3-button w3-black"
                                (click)="onSelect()">Resend Code</a></p>


                        <div *ngIf="verifyOtp" class="form-group float-label-control float-right">
                            <button type="submit" class="btn btn-info btn-block sendMsg">Verify OTP</button></div>

                        <div *ngIf="sendMessage" class="form-group float-label-control">
                            <button type="submit" class="btn btn-info btn-block sendMsg">Send Message</button></div>
                    </form>
                </div>
                <div class="col-lg-4 contactInfo">
                    <h4>Contact Information</h4>
                    <div class="media">
                        <div class="pull-left">

                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                        </div>
                        <div class="media-body">
                            <p> Plot No.72, opposite KPHB Colony,<br>Near Tulasi Vanam Apartments,<br>Opposite to Patel Kunta Huda Park,<br>Vijaya Nagar Colony, Kukatpally,
                                <br>Hyderabad, Telangana, 500072</p>
                            <!-- <p> Plot No.72, H.No 2-22-114/5/1,<br>Survey No. 121, K.R.B.Nagar Society,<br>Medchal - Malkajgiri, Vijaya Nagar Colony<br>Kukatpally,
                                hyderabad, <br>  Telangana, 500072</p> -->
                        </div>
                    </div>
                    <div class="media">
                        <div class="pull-left">
                            <i class="fa fa-phone" aria-hidden="true"></i>
                        </div>
                        <div class="media-body medianum-anchor">
                            <a href="tel:9429690678">+91 9429690678</a><br>
                            <a href="tel:8885255188">+91 8885255188</a>/<a href="tel:8885255144">144</a>
                            <!-- <a href="tel:8885255144">+91 8885255144</a> -->
                        </div>
                    </div>
                    <div class="media">
                        <div class="pull-left">
                            <i class="fa fa-phone" aria-hidden="true"></i>
                        </div>
                        <div class="media-body medianum-anchor">
                            For Escalation/Complain -<br>
                            <a href="tel:8885255144">+91 8885255144</a>/<a href="tel:8885255199">199</a>
                        </div>
                    </div>
                    <div class="media">
                        <div class="pull-left">
                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                        </div>
                        <div class="media-body">
                            <p class="mail-contact"> <a href="booking@yodrives.com"
                                    class="valour-emailId">booking@yodrives.com</a></p>
                        </div>
                    </div>
                    <div class="whatsapp-input ">
                        <div class="input-wrapper">
                       <form [formGroup]="registerForm">
                         
                    <input class="mobnum-whatsapp"  formControlName="mobnumber" type="text" (keyup)="mobWhatsapp()" onlyNumber  [ng2TelInputOptions]="{initialCountry: 'in'}" ng2TelInput  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10" placeholder="10 digit mobile number"/>
                    </form>
                        </div>
                        <a class="whatsapp-button" data-toggle = "tooltip" 
                        data-placement = "top" title = "Share with us on whatsApp" href="https://api.whatsapp.com/send?phone=91{{digitsVal.mobnumber}}&text=Hello, Welcome to www.YODrives.com"  target="_blank"> <img src="../assets/images/whatsapp.png"> </a>
                    </div>
                    <small class="text-danger" *ngIf="digitsVal.mobnumber?.length < 10 || digitsVal.mobnumber?.length == 9"> Note:The number you have entered is not valid <br> Please enter a valid 10 digits Number </small>
                    <small class="text-success" *ngIf="digitsVal.mobnumber?.length == 10">Thank you for entering Valid number</small>
                </div>
                <div class="col-lg-12 contact-map wow fadeInDown" data-wow-delay="0.5s">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15220.656554287865!2d78.4089261!3d17.4996764!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x90f94fb565f357d6!2sBhukya%20Tours%20And%20Travels!5e0!3m2!1sen!2sin!4v1597724637069!5m2!1sen!2sin"
                        frameborder="0" style="border:0;" allowfullscreen="true" aria-hidden="false"
                        tabindex="0"></iframe>
                </div>

            </div>
        </div>
    </div>
</section>