import { Component, OnInit, NgZone} from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { AgmCoreModule, MapsAPILoader } from "@agm/core";
import {CommonService} from '../shared/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-self-drive-terms-and-conditions',
  templateUrl: './self-drive-terms-and-conditions.component.html',
  styleUrls: ['./self-drive-terms-and-conditions.component.css']
})
export class SelfDriveTermsAndConditionsComponent implements OnInit {

  //constructor() { }

  title = 'Self drive cars for daily and monthly car rental in Hyderabad | Monthly car subscription in Hyderabad | Car lease in Hyderabad | yodrives.com';
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private commonService: CommonService,private mapsAPILoader: MapsAPILoader,private ngZone: NgZone,private formBuilder: FormBuilder,private router: Router
  ) { }
  
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'keywords', content: 'Self drive cars in Hyderabad, automatic self drive cars, manual and automatic self drive cars, daily and monthly self drive car rental in Hyderabad,Monthly car subscription in Hyderabad,car lease in Hyderabad for self drive, car rentals without driver, corporate car lease in Hyderabad, corporate employee car rentals, SUV self drive cars in Hyderabad, automatic self drive cars in Hyderabad, car booking tariff in hyderabad,Car rental in Hyderabad, Sanitized car rental in Hyderabad, hourly cab rentals Hyderabad,  rent a car with driver Hyderabad, Disinfected car rental in Hyderabad,Hyderabad Cabs , Car Hire , Taxi India , book cabs , Cab online , Taxi Cabs , Call Cab , Taxi on Call , Airport Transfer' },
      { name: 'author', content: 'yodrives - Bhukya Tours and Travels' },
      { name: 'keyphrase', content: 'Self drive cars in Hyderabad, automatic self drive cars, manual and automatic self drive cars, daily and monthly self drive car rental in Hyderabad,Monthly car subscription in Hyderabad,car lease in Hyderabad for self drive, car rentals without driver, corporate car lease in Hyderabad, corporate employee car rentals, SUV self drive rentals, car booking tariff in hyderabad,Car rental in Hyderabad, Sanitized car rental in Hyderabad, hourly cab rentals Hyderabad,  rent a car with driver Hyderabad, Disinfected car rental in Hyderabad,Hyderabad Cabs , Car Hire , Taxi India , book cabs , Cab online , Taxi Cabs , Call Cab , Taxi on Call , Airport Transfer' },
      { name: 'description', content: 'Self drive daily and monthly rentals subscription manual and automatic cars in Hyderabad. Hatchback , Sedan , SUV and MUV cars for lease or subscription in Hyderabad, Sanitized and disinfected car rental in Hyderabad. Hire a local hourly cab or book outstation taxi service in Hyderabad at an affordable price with YoDrives' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);
  }

}
