export const apiConstants = {
    GET_VEHICLE_TYPES: 'getVehicleTypes',
    MAPS_API_KEY: 'AIzaSyD-HPipWHPBpO_bsrk3xht40nIRvdjNezA',
    SEND_VERIFICATION_CODE:'sendVerificationCodeToMobile',
    VERIFY_VERIFICATION_CODE:'verifyVerificationCodeToMobile',
    SEND_BOOKING_MAIL:'sendBookingMail',
    SEND_SELF_DRIVE_BOOKING_MAIL:'SendSelfDriveBookingDetails',
    SEND_TAXI_DRIVER_REGISTRATION:'sendTaxiDriverRegistrationMail',
    SEND_HIRE_DRIVER_MAIL:'sendHireDriverMail',
    ADD_DRIVER_TAXI_REGISTRATION:'taxiDriverRegistration',
    ADD_HIRE_DRIVER_TAXI:'hireTaxiDriver',
    SEND_CONTACT_MAIL:'sendContactMail',


}