<section id="forgot_component">
    <div class="container">
        <div class="row justify-center">
            <div class="col-lg-4 col-md-6">
                <span class="anchor" id="formRegister"></span>
                <div class="card card-outline-secondary shadow-2xl">
                    <div class="card-header">
                        <h5 class="mb-0">FORGOT PASSWORD</h5>
                        <h5 class="mb-0" style="display: none;">VERIFY OTP</h5>
                    </div>
                    <div class="card-body">
                        <form class="form" role="form" autocomplete="off">
                            <div><p class="verify">Please enter Verification Code(OTP) sent to: </p></div>
                            <div class="form-group">
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text">+ 91</div>
                                    </div>
                                <input type="text" class="form-control" id="inputNumber" placeholder="Mobile Number" required="" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="10">
                            </div>
                            <input style="display: none;" type="text" class="form-control" id="inputOtp" placeholder="Enter OTP" required="" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" maxlength="6">
                        </div>
                            <div class="form-group butn-form text-center">
                                <button type="submit" class="btn  sub-btn">Submit</button>
                            </div>
                        </form>
                    </div>
                    <div>
                        <p class="text-center crt-cls"><a routerLink="/Login" routerLinkActive="active">Back</a></p>
                        <p class="crt-cls text-center" style="display: none;">Haven't received OTP? <a>Resend OTP</a></p>
                    </div>
                </div>
                <!-- /form card register -->
            </div>
        </div>
    </div>
    </section>
    