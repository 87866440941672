<section id="luggage-carrier-component">
    <div class="main-head">
        <p>Car Luggage Carriers Rent - Hyderabad only</p>
    </div>
    <div class="container">
        <div class="col-lg-12">
            <br><h3>Going on a long trip with family ? looking for luggage carrier rent for your car?</h3>
            <p style="font-size: 15px;margin-top: 16px;">We have hand-picked rooftop carriers that can attach to your car like Mahindra Marazzo, Innova Crysta,etc and go great with a family vacation, a big move or a road trip.</p>
        </div>
        <div class="row">
           
            <div class="style_prevu_kit col-lg-4 mode">
                <img src="../assets/images/Car_luggage_carrier-1.png" alt="">
            </div>
            <div class="style_prevu_kit col-lg-4 mode">
                <img src="../assets/images/Car_luggage_carrier-3.png" alt="">
            </div>
            <div class="style_prevu_kit col-lg-4 mode">
                <img src="../assets/images/Car_luggage_carrier-2.png" alt="">
            </div><br>
            
              <!-- <div class="style_prevu_kit col-lg-4 mode">
                  <h5 class="text-center">Paytm</h5>
                <img src="../assets/images/paytmm.png" alt=""></div> -->
              <!-- <div class="style_prevu_kit col-lg-4 mode">
                  <h5 class="text-center">PhonePe</h5>
                <img src="../assets/images/Phonepe.png" alt="" style="border-radius: 5px;">
                <p class="p-link"><a href="https://phon.pe/rgqvy7o7" target="_blank">https://phon.pe/rgqvy7o7</a></p>
              </div> -->
        </div>
        <br>
        <div class="col-lg-12 luggage-carrier-list">
            <ol>
                <li>Can easily carry upto 70 kgs of max weight.</li>
                <li>Option of Aluminium bars or MS bars.</li>
                <li>Sleek & Aerodynamic design for easy reach to load & unload your luggage.</li>
                <li>Does not disturb the functioning of the Factory-fitted OEM Sun-roof.</li>
                <li>Carry your luggage with you on your family road trip.</li>
            </ol>
        </div>
        <br>
        <div class="col-lg-12" style="padding: 0px;">
            <p style="font-size: 15px;margin-top: 16px;">As of now this service is available only in Hyderabad, For More details call us on 9429690678 email us on <a href="booking@yodrives.com">booking@yodrives.com</a> </p>
        </div>
    </div>
</section>
