<section id="about-content">
  <div class="main-head">
    <p>About Us</p>
</div>
  <div class="container" >
    <div class="row">
    <div class="col-lg-12">
      <p>YoDrives is a brand of Bhukya Tours and Travels (BTT), is a proprietorship organization started by Mr.Bhav Singh Bhukya  with the support of Tribal Cooperative Finance Corporation Ltd. (TRICOR), under the Tribal Welfare Department (TWD), Government of Telangana under CMSTEI (Chief Minister Scheduled Tribe Entrepreneurship and Innovation) Scheme. More info at <a href="https://tsobmms.cgg.gov.in/downloads/cmstei_scheme_details.pdf" target="_blank">TRICOR</a> and <a href="https://www.isb.edu/en/research-thought-leadership/research-centres-institutes/centre-for-innovation-and-entrepreneurship/TRICORHighlights.html" target="_blank">ISB</a>
      </p>
      <p>After successful completion of the training at ISB, based on his previous 17 years experience in travel and technology at <a href="https://www.etravelsmart.com/bus/" target="_blank">www.eTravelSmart.com</a> and other organizations he has started YoDrives (M/S Bhukya Tours and Travels).</p>
      <p>We have started our services in Corona Virus pandemic, so we take care all the precautions of keeping premises neat and clean.All our brand new vehicles are sanitized using  CDC Approved & WHO recommended Chemtex Alstasan Silvox Aloe Vera fumigation with Disinfection Nano Spray Gun.</p>
      <p>Our fleet includes the latest premium BS6 Innova Crysta, Mahindra Marazzo,Ford Figo Aspire,Swift Dezire and many more attached vehicles..</p>
      <p>We have selected and appointed a very good experienced team to serve our valuable customers and make their journey safe.</p>
      <p>We hope you enjoy our services and give us right and valuable suggestions and feedback to relearn and do better every time.</p>
    </div>
    <div class="col-lg-12">
      <h3>Mission</h3>
      <p>Our mission is to develop a highly successful, profitable car rental business which provides quality services by building strong and loyal customer relationships.</p>
    </div>
    <div class="col-lg-12">
      <h3>Vision</h3>
      <ul>
        <li><i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>Our short term plan is to expand to Telangana and Andhra Pradesh Tier-I and Tier-II cities and grow the business with own and leased cars.</li>
        <li><i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>Our long term plan is to expand to other states of India mainly Tier-II and Tier-III cities with own and leased cars and two wheelers.</li>
      </ul>
    </div>
    <div class="col-lg-12">
      <h3>Values</h3>
      <ul>
        <li><i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>Quality</li>
        <li><i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>Service</li>
        <li><i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>Trust</li>
        <li><i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>Safety</li>
        <li><i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>Passion & Innovation</li>
        <li><i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>Integrity</li>
        <li><i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i>Teamwork and Professionalism</li>
      </ul>
    </div>
    <div class="col-lg-4 you-tube">
      <iframe src="https://www.youtube.com/embed/X8SM7uNtXIo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="col-lg-4 you-tube">
      <iframe  src="https://www.youtube.com/embed/TKlrvjLCcJs" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="col-lg-4 you-tube">
      <iframe src="https://www.youtube.com/embed/yJyavAXgncM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="col-lg-4 you-tube">
      <iframe src="https://www.youtube.com/embed/Fn0X5LUxil8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="col-lg-4 you-tube">
      <iframe src="https://www.youtube.com/embed/MhXOluwRAE0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="col-lg-4 you-tube">
      <iframe src="https://www.youtube.com/embed/SFVZDIVYoB4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <!-- <div class="col-lg-3">
      <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FIPRTelangana%2Fvideos%2F2307545162801416%2F&show_text=0&width=560" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allowFullScreen="true"></iframe>
    </div> -->
  </div>
</div>
</section>
